@import "../../style/mixin";
@import "../../style/responsive";

$icon-color: white;
$bg-color: rgba(0, 0, 0, 0.2);
$bg-color-hover: rgba(0, 0, 0, 0.3);
$space: 10%;

.viewLarge {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(7px);
  z-index: 1000000;
  @include center;
  img {
    width: 40%;
    aspect-ratio: 1/0.9;
    transform: scale(0);
    transition: 1s all ease;
    @include tablet {
      width: 70%;
    }
    @include mobile {
      width: 90%;
      height: 30vh;
    }
    &.active {
      transform: scale(1);
    }
  }
  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    color: $icon-color;
    cursor: pointer;
    border-radius: 50%;
    padding: 5px;
    width: 50px;
    height: 50px;
    z-index: 1;
    @include mobile {
      width: 30px;
      height: 30px;
    }
    background-color: $bg-color;
    &:hover {
      background-color: $bg-color-hover;
    }
    &.back {
      left: $space;
      @include tablet {
        left: 5%;
      }
    }
    &.forward {
      right: $space;
      @include tablet {
        right: 5%;
      }
    }
  }
  .close {
    position: absolute;
    top: 50px;
    right: 50px;
    color: $icon-color;
    padding: 10px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    background-color: $bg-color;
    @include mobile {
      width: 40px;
      height: 40px;
    }
    &:hover {
      background-color: $bg-color-hover;
    }
  }
}
