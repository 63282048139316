.recipeDetails {
  width: 100%;
  text-align: right;
  margin: 50px 0;
  &.ltr {
    direction: ltr;
    text-align: left;
  }
  h1 {
    color: purple;
    margin-bottom: 30px;
  }
  img {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
  }
  .title {
    color: black;
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .text {
    .textItem {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 1.1em;
      color: rgb(58, 56, 56);
    }
  }
}
