@import "../style/mixin";
@import "../style/responsive";

.dashboard {
  width: 100%;
  @include center;
  margin: 50px 0px;
  .dashContainer {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 60%;
    @include full {
      max-width: 90%;
    }
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      max-width: 95%;
    }
  }
}

//LTR DIRECTIONS STYLES
.dashboard-ltr {
  width: 100%;
  @include center;
  margin: 50px 0px;
  .dashContainer {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 60%;
    direction: ltr;
    @include full {
      max-width: 90%;
    }
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      max-width: 95%;
    }
  }
}
