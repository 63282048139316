@import "../../style/mixin";
@import "../../style/responsive";

.success {
  width: 100%;
  height: 100vh;
  @include center;
  .succContainer {
    @include center;
    flex-direction: column;
    width: 50%;
    min-height: 300px;
    @include large {
      width: 70%;
    }
    @include tablet {
      width: 80%;
    }
    @include mobile {
      width: 97%;
    }
    .icon {
      width: 300px;
      height: 300px;
      padding: 10px;
      background-color: green;
      border-radius: 50%;
      color: white;
      @include center;
      @include tablet {
        width: 200px;
        height: 200px;
      }
      @include mobile {
        width: 170px;
        height: 170px;
      }
    }
    .paymentDetails {
      width: 80%;
      padding: 30px;
      border-radius: 10px;
      margin-top: 20px;
      @include shadowItem;
      @include mobile {
        padding: 30px 15px;
      }
      h1 {
        font-size: 2em;
        margin: 20px;
        margin-top: 0;
      }
      .result {
        width: 100%;
        font-weight: bold;
        font-size: 1.5em;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .resultItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .cart {
            direction: ltr;
          }
        }
      }
    }
    .gohomeBtn {
      width: 30%;
      padding: 10px;
      @include center;
      color: white;
      background-color: teal;
      border-radius: 10px;
      margin: auto;
      margin-top: 20px;
      cursor: pointer;
      @include large {
        width: max-content;
      }
      @include mobile {
        padding: 5px 10px;
      }
    }
  }
}
