@import "../../style/mixin";
@import "../../style/responsive";

.tabletFilter {
  width: 350px;
  height: max-content;
  background-color: orange;
  color: white;
  text-align: right;
  padding: 20px;
  // border-radius: 10px;
  @include shadow;
  @include mobile {
    width: 300px;
  }
  .filterItem {
    text-transform: capitalize;
    .title {
      text-align: right;
      background-color: white;
      color: orange;
      padding: 3px 10px;
      margin-bottom: 20px;
      border-radius: 6px;
    }
    .items {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
      align-items: start;
      padding: 0 10px;
      .item {
        display: flex;
        gap: 10px;
        font-size: 1.1em;
        cursor: pointer;
        width: max-content;
        input {
          padding: 5px;
        }
      }
    }
  }
  .search {
    width: 90%;
    background-color: teal;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
  }
}

//LTR DIRECTION STYLES
.tabletFilter-ltr {
  width: 350px;
  height: max-content;
  background-color: orange;
  color: white;
  text-align: right;
  padding: 15px 10px;
  border-radius: 10px;
  @include shadow;
  @include mobile {
    width: 300px;
  }
  .filterItem {
    text-align: left;
    text-transform: capitalize;
    .title {
      background-color: white;
      color: orange;
      padding: 3px 10px;
      margin-bottom: 20px;
      border-radius: 6px;
    }
    .items {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
      align-items: end;
      padding: 0 10px;
      .item {
        display: flex;
        gap: 10px;
        font-size: 1.1em;
        cursor: pointer;
        width: max-content;
        direction: ltr;
        input {
          padding: 5px;
          direction: ltr;
        }
      }
    }
  }
  .search {
    width: 90%;
    background-color: teal;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
  }
}
