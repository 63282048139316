@import "../../style/mixin";
@import "../../style/responsive";

.bigBlog {
  border-radius: 6px;
  // width: calc(700px);
  flex: 3;
  @include large {
    flex: none;
    width: 400px;
  }
  @include mobile {
    width: 100%;
  }
  background-color: orange;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .title {
    font-size: 3em;
    color: rgb(80, 78, 78);
    margin: 20px;
  }
  .bbContent {
    display: flex;
    gap: 15px;
    margin: 20px;
    @include full {
      flex-direction: column;
    }
    .left {
      flex: 1;
      p {
        font-size: 1.5em;
        text-align: right;
        color: rgb(80, 78, 78);
      }
      .seemore {
        margin: auto;
        margin-top: 30px;
        background-color: brown;
        color: black;
        padding: 10px;
        border-radius: 10px;
        width: 60%;
        @include center;
        cursor: pointer;
      }
    }
    .right {
      flex: 1;
      height: 100%;
      background-color: none;
      img {
        width: 100%;
        height: 100%;
        background-color: none;
        object-fit: cover;
        @include full {
          width: 100%;
          height: 250px;
        }
      }
    }
  }
}

//LTR DIRECTION STYLES
.bigBlog-ltr {
  border-radius: 6px;
  // width: calc(700px);
  flex: 3;
  @include large {
    flex: none;
    width: 400px;
  }
  @include mobile {
    width: 100%;
  }
  background-color: orange;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .title {
    font-size: 3em;
    color: rgb(80, 78, 78);
    margin: 20px;
  }
  .bbContent {
    display: flex;
    gap: 15px;
    margin: 20px;
    @include full {
      flex-direction: column;
    }
    .left {
      flex: 1;
      p {
        font-size: 1.5em;
        text-align: left;
        color: rgb(80, 78, 78);
      }
      .seemore {
        margin: auto;
        margin-top: 30px;
        background-color: brown;
        color: black;
        padding: 10px;
        border-radius: 10px;
        width: 60%;
        @include center;
        cursor: pointer;
      }
    }
    .right {
      flex: 1;
      height: 100%;
      background-color: none;
      img {
        width: 100%;
        height: 100%;
        background-color: none;
        object-fit: cover;
        @include full {
          width: 100%;
          height: 250px;
        }
      }
    }
  }
}
