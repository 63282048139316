@import "../../style/mixin";
@import "../../style/responsive";

.pay {
  width: 100%;
  height: 100vh;
  @include center;
  .payContainer {
    width: 100%;
    max-width: 60%;
    @include large {
      max-width: 75%;
    }
    @include mobile {
      max-width: 90%;
    }
    .navbarPay {
      height: 70px;
      margin-top: 30px;
      background-color: orange;
      @include tablet {
        height: 50px;
      }
      @include center-v;
      border-radius: 10px 10px 0 0;
      justify-content: space-between;
      padding: 20px;
      color: white;
      h1 {
        font-size: 1.5em;
        @include tablet {
          height: 1.2em;
        }
      }
      .homeIcon {
        cursor: pointer;
      }
    }
    .content {
      width: 100%;
      min-height: 300px;
      @include shadow;
      border-radius: 0 0 10px 10px;
      display: flex;
      padding: 10px;
      @include tablet {
        flex-direction: column;
      }
      .rightCon {
        flex: 1;
        display: flex;
        padding: 15px;
        flex-direction: column;
        align-items: start;
        border-left: 2px solid lightgray;
        @include tablet {
          border: none;
        }
        h2 {
          font-size: 1.2em;
          color: gray;
          text-align: rigth;
          margin-bottom: 10px;
        }
        .details {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 10px;
          .ditem {
            display: flex;
            gap: 5px;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            span {
              font-style: 1em;
              margin-left: auto;
            }
            input {
              width: 100%;
              padding: 10px;
            }
            textarea {
              width: 100%;
              padding: 10px;
              min-height: 100px;
            }
          }
        }
      }
      .leftCon {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 30px;
        h2 {
          font-size: 1.2em;
        }
        .paydetails {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .pdetailsItem {
            @include center-h;
            justify-content: space-between;
            font-size: 1em;
            .discount {
              color: red;
              font-weight: bold;
            }
            .finall {
              color: green;
              font-weight: bold;
            }
            .finalltext {
              font-weight: bold;
            }
          }
          .hr {
            height: 2px;
          }
        }
        .payBtn {
          @include center;
          width: 80%;
          margin: auto;
          margin-bottom: 10px;
          background-color: teal;
          color: white;
          border-radius: 6px;
          padding: 10px;
          cursor: pointer;
          border: none;
          padding: 10px;
          @include tablet {
            margin-top: 30px;
            margin-bottom: 0;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
        .toPay {
          width: 80%;
          @include center;
          color: white;
          background-color: green;
          padding: 10px;
          border-radius: 6px;
          margin: auto;
          margin-bottom: 10px;
        }
      }
    }
  }
}

//LTR DIRECTION STYLES
.pay-ltr {
  width: 100%;
  height: 100vh;
  @include center;
  .payContainer {
    width: 100%;
    max-width: 60%;
    @include large {
      max-width: 75%;
    }
    @include mobile {
      max-width: 90%;
    }
    .navbarPay {
      height: 70px;
      margin-top: 30px;
      background-color: orange;
      direction: ltr;
      @include tablet {
        height: 50px;
      }
      @include center-v;
      border-radius: 10px 10px 0 0;
      justify-content: space-between;
      padding: 20px;
      color: white;
      h1 {
        font-size: 1.5em;

        @include tablet {
          height: 1.2em;
        }
      }
      .homeIcon {
        cursor: pointer;
      }
    }
    .content {
      width: 100%;
      min-height: 300px;
      @include shadow;
      border-radius: 0 0 10px 10px;
      display: flex;
      padding: 10px;
      @include tablet {
        flex-direction: column;
      }
      .rightCon {
        flex: 1;
        display: flex;
        padding: 15px;
        flex-direction: column;
        align-items: start;
        border-left: 2px solid lightgray;
        @include tablet {
          border: none;
        }
        h2 {
          font-size: 1.2em;
          margin-right: auto;
          color: gray;
          text-align: rigth;
          margin-bottom: 10px;
        }
        .details {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 10px;
          .ditem {
            display: flex;
            gap: 5px;
            width: 100%;
            flex-direction: column;
            align-items: end;
            justify-content: space-between;
            span {
              font-style: 1em;
            }
            input {
              width: 100%;
              padding: 10px;
              direction: ltr;
            }
            textarea {
              width: 100%;
              padding: 10px;
              min-height: 100px;
              direction: ltr;
            }
          }
        }
      }
      .leftCon {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 30px;
        h2 {
          font-size: 1.2em;
        }
        .paydetails {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .pdetailsItem {
            @include center-h;
            justify-content: space-between;
            font-size: 1em;
            direction: ltr;
            .discount {
              color: red;
              font-weight: bold;
            }
            .finall {
              color: green;
              font-weight: bold;
            }
            .finalltext {
              font-weight: bold;
            }
          }
          .hr {
            height: 2px;
          }
        }
        .payBtn {
          @include center;
          width: 80%;
          margin: auto;
          margin-bottom: 10px;
          background-color: teal;
          color: white;
          border-radius: 6px;
          padding: 10px;
          cursor: pointer;
          border: none;
          padding: 10px;
          @include tablet {
            margin-top: 30px;
            margin-bottom: 0;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
        .toPay {
          width: 80%;
          @include center;
          color: white;
          background-color: green;
          padding: 10px;
          border-radius: 6px;
          margin: auto;
          margin-bottom: 10px;
        }
      }
    }
  }
}
