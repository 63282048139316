@import "../../style/responsive";
@import "../../style/mixin";

.pIntroduction {
  width: 100%;
  margin: 20px 0;
  border: 0.2px solid lightgray;
  padding: 10px;
  background-color: white;
  .header {
    padding: 10px;
    border-bottom: 2px solid lightgray;
    text-align: right;
    h4 {
      font-size: 1.7em;
      margin-bottom: 5px;
    }
    .fill {
      color: gold;
    }
  }
  .picontent {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 40px;
    @include full {
      gap: 10px;
    }
    @include tablet {
      flex-direction: column;
    }
    .imgaes {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include tablet {
        flex: none;
        width: 100%;
      }
      .mainimg {
        width: 100%;
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          min-height: 300px;
          object-fit: cover;
        }
      }
      .imageGallery {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        right: 0;
        left: 0;
        // margin: auto;
        gap: 10px;
        z-index: 2;
        .img-gItem {
          width: 50px;
          height: 50px;
          border: 1px solid lightgray;
          margin-bottom: 2px;
          cursor: pointer;
          transition: 1s all ease;
          &:hover {
            transform: scale(1.1);
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .details {
      flex: 1.5;
      padding: 10px;
      text-align: right;
      @include tablet {
        margin-top: 20px;
        flex: none;
        width: 90%;
      }
      .top {
        border-bottom: 0.5px solid lightgray;
        .price {
          color: red;
          font-size: 1.2em;
          @include center-v;
          gap: 10px;
        }
        .garanti {
          color: lightblue;
          margin: 10px 0;
        }
      }
      .center {
        border-bottom: 1px solid lightgray;
        padding-bottom: 20px;
        .status {
          color: green;
          margin: 15px;
          font-size: 1.1em;
        }
        .desc {
          margin-bottom: 20px;
        }
        .btns {
          display: flex;
          // align-items: center;
          .counter-btns {
            display: flex;
            gap: 5px;
            align-items: center;
            border: 1px solid lightgray;
            color: rgb(89, 87, 87);
          }
          .add-btn {
            background-color: gold;
            margin-left: auto;
            height: 100%;
            padding: 5px 10px;
            margin-right: 20px;
            cursor: pointer;
          }
          .like-btn {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 20px 0;
        font-size: 0.9em;
      }
    }
  }
}

//LTR DIRECTION STYLES
.pIntroduction-ltr {
  width: 100%;
  margin: 20px 0;
  border: 0.2px solid lightgray;
  padding: 10px;
  background-color: white;
  .header {
    padding: 10px;
    border-bottom: 2px solid lightgray;
    text-align: right;
    text-align: left;
    h4 {
      font-size: 1.7em;
      margin-bottom: 5px;
    }
    .commentsCounter {
      direction: ltr;
      .fill {
        color: gold;
      }
    }
  }
  .picontent {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 40px;
    direction: ltr;
    @include full {
      gap: 10px;
    }
    @include tablet {
      flex-direction: column;
    }
    .imgaes {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include tablet {
        flex: none;
        width: 100%;
      }
      .mainimg {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          min-height: 300px;
          object-fit: cover;
        }
      }
      .imageGallery {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        right: 0;
        left: 0;
        // margin: auto;
        gap: 10px;
        z-index: 2;
        .img-gItem {
          width: 50px;
          height: 50px;
          border: 1px solid lightgray;
          margin-bottom: 2px;
          cursor: pointer;
          transition: 1s all ease;
          &:hover {
            transform: scale(1.1);
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .details {
      flex: 1.5;
      padding: 10px;
      text-align: left;
      @include tablet {
        margin-top: 20px;
        flex: none;
        width: 90%;
      }
      .top {
        border-bottom: 0.5px solid lightgray;
        .price {
          color: red;
          font-size: 1.2em;
          display: flex;
          justify-content: end;
          @include center-v;
          gap: 10px;
        }
        .garanti {
          color: lightblue;
          margin: 10px 0;
        }
      }
      .center {
        border-bottom: 1px solid lightgray;
        padding-bottom: 20px;
        .status {
          color: green;
          margin: 15px;
          margin-left: 0;
          font-size: 1.1em;
        }
        .desc {
          margin-bottom: 20px;
          text-align: left;
        }
        .btns {
          display: flex;
          .counter-btns {
            display: flex;
            gap: 5px;
            margin-left: auto;
            margin-right: 10px;
            align-items: center;
            border: 1px solid lightgray;
            color: rgb(89, 87, 87);
            order: 2;
          }
          .add-btn {
            background-color: gold;
            height: 100%;
            padding: 5px 10px;
            margin-right: 20px;
            cursor: pointer;
            order: 1;
          }
          .like-btn {
            display: flex;
            align-items: center;
            gap: 5px;
            order: 3;
          }
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 20px 0;
        font-size: 0.9em;
      }
    }
  }
}
