@import "../../../style/mixin";
@import "../../../style/responsive";

.ticketItem {
  @include shadowItem;
  border-radius: 10px;
  min-height: 60px;
  @include center;
  padding: 10px;
  cursor: pointer;
  .tiContainer {
    width: 100%;
    @include center-v;
    gap: 10px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 0.5px solid lightgray;
    }
    .details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      .TicketTop {
        display: flex;
        justify-content: space-between;
        color: gray;
        .date-delivery {
          @include center-v;
          gap: 10px;
          .date {
            color: black;
          }
        }
      }
      .bottom {
        color: rgb(70, 68, 68);
        text-align: right;
      }
    }
  }
}

//LTR DIRECTION STYLES
.ticketItem-ltr {
  @include shadowItem;
  border-radius: 10px;
  min-height: 60px;
  @include center;
  padding: 10px;
  cursor: pointer;
  .tiContainer {
    width: 100%;
    @include center-v;
    gap: 10px;
    direction: ltr;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 0.5px solid lightgray;
    }
    .details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      .TicketTop {
        display: flex;
        justify-content: space-between;
        color: gray;
        direction: ltr;
        .date-delivery {
          @include center-v;
          gap: 10px;
          .date {
            color: black;
          }
        }
      }
      .bottom {
        color: rgb(70, 68, 68);
        text-align: left;
      }
    }
  }
}
