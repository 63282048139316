@import "../../style/responsive";

.blog {
  width: 100%;
  background: url("../../../public/images/simple-background.jpg");
  padding: 60px 0px;
  display: flex;
  justify-content: center;
  .blogContainer {
    width: 100%;
    max-width: 70%;
    display: flex;
    gap: 50px;
    @include large {
      max-width: 90%;
      gap: 20px;
    }
    @include tablet {
      flex-direction: column;
    }
    .imgBLog {
      flex: 1;
      @include large {
        flex: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;
        order: 1;
      }
      img {
        width: 400px;
        height: 400px;
        border-radius: 50%;
        @include tablet {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 430px;
          height: 430px;
        }
        @include tablet {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 350px;
          height: 350px;
        }
      }
    }
    .description {
      flex: 1.5;
      text-align: right;
      height: 100%;
      @include large {
        flex: 1;
      }
      @include tablet {
        text-align: center;
        order: 2;
      }
      .blogTitle {
        font-weight: 600;
        font-size: 2em;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .titr {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 3em;
        color: green;
        @include large {
          font-size: 2.5em;
        }
      }
      p {
        width: 70%;
        text-align: justify;
        font-size: 1.5em;
        margin: 20px 0px;
        @include large {
          font-size: 1.2em;
        }
      }
      .learnMore {
        margin-top: auto;
        width: max-content;
        background-color: orange;
        color: white;
        padding: 7px 12px;
        border-radius: 6px;
        cursor: pointer;
        font-weight: 600;
        text-transform: uppercase;
        &:hover {
          background-color: rgba(255, 166, 0, 0.765);
        }
      }
    }
  }
}
