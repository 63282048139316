@import "../../style/mixin";
@import "../../style/responsive";

.imgBanner {
  width: 100%;
  @include center;
  img {
    width: 100%;
    height: 50vh;
    @include tablet {
      height: 35vh;
    }
    @include mobile {
      height: 25vh;
    }
  }
}
