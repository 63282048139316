@import "../../style/mixin";
@import "../../style/responsive";

.blogsContent {
  padding-bottom: 30px;
  width: 100%;
  background-image: url("../../../public/images/simple-background.jpg");
  @include center-h;
  .bcContainer {
    width: 100%;
    max-width: 70%;
    @include tablet {
      max-width: 90%;
    }
    h3 {
      color: orange;
      font-size: 3em;
      text-align: right;
      margin: 20px;
    }
    .contentsBlogs {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .top {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: start;
      }
      .bottom {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: start;
      }
    }
  }
}
