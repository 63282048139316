@import "../../style/mixin";

.generateSlider {
  width: 100%;
  height: max-content;
  margin: 30px 0;
  .sItem {
    // background-color: red;
    border: 1px solid lightgray;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    .imgContainer {
      width: 100%;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .details {
      height: 100px;
      padding: 10px;
      text-align: right;
      font-size: 1.1em;
      @include center;
      align-items: start;
      position: relative;
      background-color: rgba(218, 185, 124, 0.852);
      .play {
        position: absolute;
        left: 10px;
        bottom: 15px;
        width: 40px;
        height: 40px;
        z-index: 10;
        color: lightgray;
      }
    }
    .cover {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(240, 222, 165, 0.3);
    }
    &:hover {
      .play {
        color: white;
        cursor: pointer;
      }
      .cover {
        display: flex;
      }
    }
  }
}
