@import "../../style/mixin";

.pannelItems {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 10px;
  border-radius: 0px 0px 6px 6px;
  z-index: 1;
  @include shadow;
  .pItems {
    padding: 2px 10px;
    font-size: 0.8em;
    font-weight: bold;
    &:hover {
      color: rgb(129, 209, 235);
    }
  }
}
