@mixin full {
  @media (max-width: 1360px) {
    @content;
  }
}

@mixin large {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 450px) {
    @content;
  }
}

@mixin mini {
  @media (max-width: 380px) {
    @content;
  }
}
