.exampleSlider {
  width: 100%;
  background-color: white;
  padding: 30px 10px;
  border: 1px solid lightgray;
  .sliderHeader {
    // width: max-content;
    text-align: right;
    padding: 5px;
    font-weight: bold;
    margin-bottom: 20px;
    background-color: orange;
    border-radius: 6px;
    color: white;
  }
  .slideItem {
    width: 200px;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 5px;
    transition: 0.5s all ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
    }
    .imgContainer {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }
}
