@import "../../style/mixin";
@import "../../style/responsive";

.productsConten {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .pcContainer {
    width: 100%;
    max-width: 80%;
    @include large {
      max-width: 95%;
    }
    h1 {
      width: max-content;
      margin: 30px 0;
      color: purple;
    }
    .producTypes {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 20px;
      border-bottom: 2px solid lightblue;
      padding-bottom: 10px;
      .ptItem {
        padding: 10px;
        color: rgb(90, 88, 88);
        font-weight: 600;
        list-style: none;
        border-radius: 6px;
        font-size: 1.1em;
        &:hover {
          background-color: orange;

          cursor: pointer;
        }
        &.active {
          background-color: orange;
          color: black;
        }
      }
      .filbtn {
        display: none;
        color: purple;
        @include tablet {
          display: flex;
        }
      }
    }
    .productsResult {
      display: flex;
      align-items: start;
      gap: 15px;
      margin-bottom: 50px;
      @include tablet {
        flex-direction: column;
      }
      .filer {
        flex: 1;
        border: 1px solid lightgray;
        height: max-content;
        position: sticky;
        top: 80px;
        right: 0;
        background-color: orange;
        color: white;
        text-align: right;
        padding: 15px 10px;
        border-radius: 10px;
        @include shadow;
        @include tablet {
          display: none;
        }
        .title {
          background-color: white;
          color: orange;
          padding: 3px 10px;
          margin-bottom: 20px;
          border-radius: 6px;
        }
        .items {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 20px;
          padding: 0 10px;

          //
          align-items: start;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 1.1em;
            cursor: pointer;
            width: max-content;
            input {
              padding: 5px;
            }
          }
        }
        .search {
          width: 90%;
          background-color: teal;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          border-radius: 6px;
          cursor: pointer;
          font-weight: 600;
        }
      }
      .results {
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgb(251, 246, 246);
        padding: 20px;
        border-radius: 6px;
        @include tablet {
          order: 2;
        }
        h1 {
          width: max-content;
          margin: 0 0 20px 20px;
          color: purple;
          margin-left: auto;
        }
        .resContainer {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 20px;
          height: 100%;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
}

//PRODUCTS CONTENT - LTR
.productsConten-ltr {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .pcContainer {
    width: 100%;
    max-width: 80%;
    direction: ltr;
    text-transform: capitalize;
    @include large {
      max-width: 95%;
    }
    .producTypes {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 20px;
      direction: ltr;
      border-bottom: 2px solid lightblue;
      padding-bottom: 10px;
      @include mobile {
        gap: 5px;
      }
      .ptItem {
        padding: 10px;
        color: rgb(90, 88, 88);
        font-weight: 600;
        list-style: none;
        border-radius: 6px;
        font-size: 1em;
        @include mobile {
          font-size: 0.9em;
        }
        &:hover {
          background-color: orange;

          cursor: pointer;
        }
        &.active {
          background-color: orange;
          color: black;
        }
      }
      .filbtn {
        display: none;
        color: purple;
        @include tablet {
          display: flex;
        }
      }
    }
    .productsResult {
      display: flex;
      align-items: start;
      gap: 15px;
      margin-bottom: 50px;
      direction: ltr;
      @include tablet {
        flex-direction: column;
      }
      .filer {
        flex: 1;
        border: 1px solid lightgray;
        height: max-content;
        position: sticky;
        top: 80px;
        right: 0;
        background-color: orange;
        color: white;
        text-align: right;
        padding: 15px 10px;
        border-radius: 10px;
        @include shadow;
        @include tablet {
          display: none;
        }
        .filterItem {
          text-align: left;
          text-transform: capitalize;
          .title {
            background-color: white;
            color: orange;
            padding: 3px 10px;
            margin-bottom: 20px;
            border-radius: 6px;
          }
          .items {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;
            align-items: end;
            padding: 0 10px;
            .item {
              display: flex;
              gap: 10px;
              font-size: 1.1em;
              cursor: pointer;
              width: max-content;
              direction: ltr;
              input {
                padding: 5px;
                direction: ltr;
              }
            }
          }
        }

        .search {
          width: 90%;
          background-color: teal;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          border-radius: 6px;
          cursor: pointer;
          font-weight: 600;
        }
      }
      .results {
        flex: 3;
        background-color: rgb(251, 246, 246);
        padding: 20px;
        border-radius: 6px;
        text-align: left;
        height: max-content;
        @include tablet {
          order: 2;
        }
        h1 {
          width: max-content;
          margin: 0 0 20px 20px;
          color: purple;
          margin-right: auto;
        }
        .resContainer {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 20px;
          height: 100%;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
}
