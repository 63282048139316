@import "../../style/responsive";

.slider {
  width: 100%;
  height: 550px;
  background: url("../../../public/images/simple-background.jpg");
  display: flex;
  align-items: center;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  position: relative;
  .row {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.2);
    color: gray;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 10px;
    @include tablet {
      width: 50px;
      height: 50px;
      padding: 5px;
    }
    cursor: pointer;
    z-index: 1;
    &.right {
      right: 15px;
    }
    &.left {
      left: 15px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
    }
  }
  .sliderWaraper {
    display: flex;
    width: max-content;
    gap: 30px;
    height: 80%;
    transition: 0.5s transform ease;
    .sliderItem {
      width: 350px;
      background-color: white;
      height: 100%;
      border-radius: 6px;
      overflow: hidden;
      .imgContainer {
        position: relative;
        height: 300px;
        img {
          width: 100%;
          height: 100%;
        }
        .shadow {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          display: none;
          align-items: center;
          justify-content: center;
          color: white;
          .icons {
            display: flex;
            align-items: center;
            gap: 10px;
            .shadowIcon {
              cursor: pointer;
            }
          }
        }
        &:hover {
          .shadow {
            display: flex;
          }
        }
      }
      .proName {
        color: orange;
        margin: 10px 0;
      }
      .desc {
        min-height: 120px;
        padding: 10px;
        padding-top: 0;
        text-align: justify;
        font-weight: 500;
        font-size: 1em;
        height: 100px;
        margin: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .show-more {
          font-size: 0.8em;
          margin: auto;
          margin-bottom: 20px;
          width: max-content;
          background-color: orange;
          color: white;
          border-radius: 10px;
          padding: 3px 5px;
          cursor: pointer;
        }
      }
    }
  }
  .seeMore-btn {
    position: absolute;
    color: red;
    z-index: 100;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: orange;
    color: white;
    width: max-content;
    border-radius: 6px;
    padding: 3px 12px;
    cursor: pointer;
    font-weight: 580;
    font-size: 1.1em;
    text-transform: uppercase;
  }
}
