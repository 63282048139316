@import "../../../style/mixin";
@import "../../../style/responsive";

.changePass {
  @include shadowItem;
  border: 1px solid lightgray;
  border-radius: 10px;
  .cpContainer {
    padding: 20px;
    h3 {
      text-align: right;
      margin-bottom: 20px;
      font-size: 1.5em;
    }
    .box {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 20px;
      .boxItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        span {
          margin-left: auto;
        }
        input {
          width: 60%;
          padding: 10px;
          border: 1px solid teal;
          outline-color: lightblue;
          border-radius: 6px;
          direction: ltr;
          text-align: center;
          @include tablet {
            width: 70%;
          }
          @include mobile {
            width: 100%;
          }
        }
      }
    }
    .changeBtn {
      width: max-content;
      background-color: teal;
      cursor: pointer;
      color: white;
      border-radius: 6px;
      padding: 7px 15px;
      margin-right: auto;
      @include mobile {
        margin-right: auto;
        width: 100%;
        padding: 10px;
      }
    }
  }
}

//LTR DIRECTION STYLES
.changePass-ltr {
  @include shadowItem;
  border: 1px solid lightgray;
  border-radius: 10px;
  .cpContainer {
    padding: 20px;
    h3 {
      text-align: left;
      margin-bottom: 20px;
      font-size: 1.5em;
    }
    .box {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 20px;
      .boxItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        direction: ltr;
        gap: 10px;
        span {
          margin-right: auto;
          direction: ltr;
        }
        input {
          width: 60%;
          padding: 10px;
          border: 1px solid teal;
          outline-color: lightblue;
          border-radius: 6px;
          direction: ltr;
          text-align: center;
          @include tablet {
            width: 70%;
          }
          @include mobile {
            width: 100%;
          }
        }
      }
    }
    .changeBtn {
      width: max-content;
      background-color: teal;
      cursor: pointer;
      color: white;
      border-radius: 6px;
      padding: 7px 15px;
      margin-left: auto;
      @include mobile {
        margin-right: auto;
        width: 100%;
        padding: 10px;
      }
    }
  }
}
