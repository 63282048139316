@import "../../style/mixin";
@import "../../style/responsive";

.imgGallery {
  @include center-h;
  .imgGContainers {
    @include maxWidth(60%);
    @include tablet {
      @include maxWidth(80%);
    }
    @include mobile {
      @include maxWidth(85%);
    }
    text-align: right;
    h1 {
      @include title;
    }
    h2 {
      @include subtitle;
    }
    p {
      @include para;
      text-align: justify;
    }
    .images {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 20px 0;
      .imcontain {
        width: 200px;
        height: 200px;
        position: relative;
        cursor: pointer;
        &:hover {
          .textCon {
            display: flex;
            span {
              transition: 1s all ease;
              transform: scale(1);
            }
          }
          .magnifire {
            display: none;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
        .textCon {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          @include center;
          color: white;
          font-weight: bold;
          background-color: rgba(0, 0, 0, 0.4);
          display: none;
          transition: 1s all ease-in-out;
          span {
            transform: scale(0);
            font-size: 1.1em;
          }
        }
      }
      .magnifire {
        position: absolute;
        @include center;
        right: 0;
        bottom: 0;
        padding: 10px;
        color: white;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

//LTR DIRECTION STYLES
.imgGallery-ltr {
  @include center-h;
  .imgGContainers {
    direction: ltr;
    text-align: left;
    @include maxWidth(60%);
    @include tablet {
      @include maxWidth(80%);
    }
    @include mobile {
      @include maxWidth(85%);
    }

    h1 {
      @include title;
    }
    h2 {
      @include subtitle;
    }
    p {
      @include para;
      text-align: justify;
      direction: ltr;
    }
    .images {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 20px 0;
     direction: ltr;
      .imcontain {
        width: 200px;
        height: 200px;
        position: relative;
        cursor: pointer;
        &:hover {
          .textCon {
            display: flex;
            span {
              transition: 1s all ease;
              transform: scale(1);
            }
          }
          .magnifire {
            display: none;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
        .textCon {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          @include center;
          color: white;
          font-weight: bold;
          background-color: rgba(0, 0, 0, 0.4);
          display: none;
          transition: 1s all ease-in-out;
          span {
            transform: scale(0);
            font-size: 1.1em;
          }
        }
      }
      .magnifire {
        position: absolute;
        @include center;
        right: 0;
        bottom: 0;
        padding: 10px;
        color: white;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
