@import "../../style/mixin";

.videoPlayer {
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600000;
  @include center;
  .vpContainer {
    @include maxWidth(70%);
    height: 50vh;
    transform: scale(0);
    transition: 1s transform ease;
    @include center;
    &.active {
      transform: scale(1);
    }
    video {
      border-radius: 10px;
      width: 100%;
    }
  }
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    @include center;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s ease;
    color: orange;
    border: 1px solid white;
    background-color: rgb(232, 227, 227);
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
