@import "../../style/responsive";
@import "../../style/mixin";

.comments {
  width: 100%;
  margin: 20px 0;
  border: 0.5px solid lightgray;
  padding: 10px;
  background-color: white;
  .head {
    display: flex;
    gap: 5px;
    background-color: orange;
    border-radius: 6px;
    color: white;
    span {
      width: max-content;
      padding: 5px;
      font-weight: bold;
    }
  }
  .cContent {
    .top {
      display: flex;
      padding: 20px;
      @include tablet {
        flex-direction: column;
      }
      .score {
        flex: 1;
        background-color: red($color: #000000);
        padding: 10px 0;
        text-align: right;
        .titr {
          font-weight: bold;
        }
        .poan {
          color: green;
          font-size: 1.6em;
        }
        .stars {
          display: flex;
          align-items: center;
          .icon {
            color: orange;
          }
        }
        .commentCount {
          width: max-content;
          margin: 10px 0;
        }
        .ratingStars {
          .rating-item {
            display: flex;
            align-items: center;
            gap: 5px;
            span {
              font-size: 0.8em;
            }
            .progress {
              width: 100px;
              background: lightgray;
              border-radius: 10px;
              height: 5px;
              .fill {
                background-color: green;
                width: 10%;
                height: 100%;
                border-radius: 10px;
              }
            }
          }
        }
      }
      .newComment {
        flex: 1.5;
        text-align: right;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .titr {
          font-weight: 600;
        }
        .yourRate {
          .rate-starts {
            margin: 8px 0;
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
        textarea {
          width: 100%;
          min-height: 150px;
          padding: 10px;
          font-size: 1.1em;
        }
        .send-comment {
          background-color: gold;
          width: max-content;
          margin-right: auto;
          padding: 5px;
          font-weight: 600;
          font-size: 0.9em;
          cursor: pointer;
        }
      }
    }
    .bottom {
      margin: 10px 0px;
      text-align: right;
      .header {
        background-color: lightgray;
        padding: 5px 10px;
        margin-bottom: 15px;
      }
      .commentItems {
        //transform to single componenets
        @include center;
        flex-direction: column;
        width: 100%;
        .noComment {
          padding: 10px;
          margin: auto;
        }
      }
      .seeMore {
        color: rgb(105, 105, 168);
        background-color: lightblue;
        padding: 5px;
        border-radius: 10px;
        font-size: 0.8em;
        cursor: pointer;
        width: max-content;
        margin-right: auto;
        margin-left: 15px;
        display: flex;
        align-items: center;
        &:hover {
          color: blue;
        }
      }
    }
  }
}

//LTR DIRECTION STYLES

.comments-ltr {
  width: 100%;
  margin: 20px 0;
  border: 0.5px solid lightgray;
  padding: 10px;
  background-color: white;
  .head {
    display: flex;
    gap: 5px;
    background-color: orange;
    border-radius: 6px;
    color: white;
    padding: 0px 20px;
    span {
      width: max-content;
      padding: 5px;
      font-weight: bold;
      margin-right: auto;
    }
  }
  .cContent {
    .top {
      display: flex;
      padding: 20px;
      direction: ltr;
      @include tablet {
        flex-direction: column;
      }
      .score {
        flex: 1;
        background-color: red($color: #000000);
        padding: 10px 0;
        text-align: left;
        .titr {
          font-weight: bold;
        }
        .poan {
          color: green;
          font-size: 1.6em;
        }
        .stars {
          display: flex;
          align-items: center;
          justify-content: start;
          direction: ltr;
          .icon {
            color: orange;
          }
        }
        .commentCount {
          width: max-content;
          margin: 10px 0;
          margin-right: auto;
        }
        .ratingStars {
          .rating-item {
            display: flex;
            align-items: center;
            direction: ltr;
            gap: 5px;
            margin: 10px;
            span {
              font-size: 0.8em;
            }
            .progress {
              width: 100px;
              background: lightgray;
              border-radius: 10px;
              height: 5px;
              .fill {
                background-color: green;
                width: 10%;
                height: 100%;
                border-radius: 10px;
              }
            }
          }
        }
      }
      .newComment {
        flex: 1.5;
        text-align: left;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .titr {
          font-weight: 600;
        }
        .yourRate {
          .rate-starts {
            width: max-content;
            margin: 8px 0;
            margin-right: auto;
            display: flex;
            align-items: center;
            gap: 5px;
            direction: ltr;
          }
        }
        textarea {
          width: 100%;
          min-height: 150px;
          padding: 10px;
          font-size: 1.1em;
          direction: ltr;
        }
        .send-comment {
          background-color: gold;
          width: max-content;
          margin-right: auto;
          padding: 5px;
          font-weight: 600;
          font-size: 0.9em;
          cursor: pointer;
        }
      }
    }
    .bottom {
      margin: 10px 0px;
      text-align: left;
      .header {
        background-color: lightgray;
        padding: 5px 10px;
        margin-bottom: 15px;
      }
      .commentItems {
        //transform to single componenets
        @include center;
        flex-direction: column;
        width: 100%;
        .noComment {
          padding: 10px;
          margin: auto;
        }
      }
      .seeMore {
        color: rgb(105, 105, 168);
        background-color: lightblue;
        padding: 5px;
        border-radius: 10px;
        font-size: 0.8em;
        cursor: pointer;
        width: max-content;
        margin-left: auto;
        margin-right: 15px;
        display: flex;
        align-items: center;
        &:hover {
          color: blue;
        }
      }
    }
  }
}
