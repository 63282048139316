@import "../../style/mixin";
@import "../../style/responsive";

.serach {
  position: fixed;
  top: 68px;
  right: 0;
  width: 100%;
  height: calc(100vh - 68px);
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  .searchContainer {
    width: 100%;
    max-width: 90%;
    .box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 45%;
      min-height: 100px;
      background-color: white;
      border-end-end-radius: 10px;
      border-end-start-radius: 10px;
      @include tablet {
        width: 80%;
      }
      @include mobile {
        width: 90%;
      }
      .noItems {
        width: 100%;
        height: 100px;
        @include center;
        margin: auto;
        color: black;
        font-size: 1.3em;
      }
    }
  }
}
