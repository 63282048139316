.yours {
  background-color: green;
  border-radius: 10px;
  margin-right: auto;
  padding: 10px;
  color: white;
  text-align: right;
  width: max-content;
  max-width: 250px;
  .time {
    text-align: left;
    margin-top: 5px;
    font-size: 0.8em;
    color: rgb(215, 210, 210);
  }
}
.supports {
  background-color: white;
  padding: 10px;
  max-width: 100px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: none;
  text-align: right;
  width: max-content;
  width: max-content;
  max-width: 250px;
  .time {
    text-align: left;
    justify-content: start;
    margin-top: 5px;
    font-size: 0.8em;
    color: rgb(148, 145, 145);
  }
}
