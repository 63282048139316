@import "../../style/mixin";
@import "../../style/responsive";

$margin_bottom_main: 15px;
$margin_bottom_sub: 10px;

.pollination {
  @include page-Width;
  flex-direction: column;
  align-items: center;
  .bannerContainer {
    width: 100%;
    height: 50vh;
    @include tablet {
      height: 40vh;
    }
    @include mobile {
      height: 25vh;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .poContainer {
    margin: 20px 0;
    @include maxWidth(60%);
    @include full {
      @include maxWidth(73%);
    }
    @include tablet {
      @include maxWidth(80%);
    }
    @include mobile {
      @include maxWidth(90%);
    }
    .poContent {
      text-align: right;
      .title {
        font-size: $title-size;
        color: $title-color;
        margin: 30px 0;
      }
      .subtitle {
        color: $subtitle-color;
        font-size: $subtitle-size;
        margin-bottom: $margin_bottom_sub;
      }
      .text {
        font-size: $text-size;
        color: $text-color;
        margin-bottom: $margin_bottom_main;
        line-height: 30px;
      }
      .items {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: $margin_bottom_main;
        img {
          width: 80px;
        }
      }
    }
  }
}

//LTR DIRECTION STYLES
.pollination-ltr {
  @include page-Width;
  flex-direction: column;
  align-items: center;
  .bannerContainer {
    width: 100%;
    height: 50vh;
    @include tablet {
      height: 40vh;
    }
    @include mobile {
      height: 25vh;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .poContainer {
    margin: 20px 0;
    @include maxWidth(60%);
    @include full {
      @include maxWidth(73%);
    }
    @include large {
      @include maxWidth(90%);
    }
    @include tablet {
      // @include maxWidth(80%);
    }
    @include mobile {
      @include maxWidth(90%);
    }
    .poContent {
      text-align: left;
      .title {
        font-size: $title-size;
        color: $title-color;
        margin: 30px 0;
      }
      .subtitle {
        color: $subtitle-color;
        font-size: $subtitle-size;
        margin-bottom: $margin_bottom_sub;
      }
      .text {
        font-size: $text-size;
        color: $text-color;
        margin-bottom: $margin_bottom_main;
        line-height: 30px;
        text-align: left;
      }
      .items {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: $margin_bottom_main;
        img {
          width: 80px;
        }
      }
    }
  }
}
