@import "../../style/mixin";
@import "../../style/responsive";

.rContent {
  width: 100%;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  .rcContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 50px 0px;
    @include maxWidth(65%);
    @include large {
      @include maxWidth(75%);
    }
    @include tablet {
      @include maxWidth(80%);
    }
    @include mobile {
      @include maxWidth(90%);
    }
    //start row mode
    &.rowMode {
      max-width: 70%;
      flex-direction: row;
      gap: 10px;
      .title {
        font-size: 1.5em;
      }
      .search {
        width: 40%;
        input {
          width: 90%;
          padding: 10px;
        }
        .icon {
          width: 40px;
          height: 40px;
        }
      }
      .filters {
        // gap: 10px;
        .filter {
          // gap: 5px;
          padding: 8px;
          .titr {
            font-size: 1.1em;
          }
        }
      }
    }
    //end row Mode
    .title {
      color: purple;
      font-weight: 600;
      font-size: 3em;
      @include tablet {
        font-size: 2em;
      }
    }
    .search {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      text-align: center;
      input {
        width: 50%;
        padding: 15px;
        font-size: 0.9em;
        @include large {
          width: 80%;
        }
        @include tablet {
          width: 90%;
        }
      }
      .icon {
        color: orange;
        width: 50px;
        height: 50px;
        @include tablet {
          width: 40px;
          height: 40px;
        }
        cursor: pointer;
      }
    }
    .filters {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      @include tablet {
        gap: 5px;
      }
      .filter {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-left: 1px solid gray;
        padding: 5px 10px;
        position: relative;
        @include tablet {
          gap: 2px;
        }
        .titr {
          font-size: 1.5em;
          font-weight: 500;
          cursor: pointer;
          @include large {
            font-size: 1.2em;
          }
          @include tablet {
            font-size: 1em;
            font-weight: 600;
          }
          @include mobile {
            font-size: 0.8em;
            font-weight: 600;
          }
        }
        .icon {
          cursor: pointer;
          z-index: 100;
        }
        .meals {
          position: absolute;
          top: 100%;
          right: 0;
          width: 100%;
          overflow: hidden;
          z-index: 1000;
          .mealContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
            transform: translateY(-100%);
            background-color: white;
            transition: 0.5s transform ease;
            border-radius: 6px;
            &.active {
              transform: translateY(0);
            }
            .meal {
              text-align: right;
              padding: 0px 5px;
              border-radius: 6px;
              cursor: pointer;
              font-weight: 500;
              color: gray;
              &:hover {
                background-color: lightgray;
              }
            }
          }
        }
      }
    }
  }
}
