@import "../../style/mixin";
@import "../../style/responsive";

.mobileNav {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 100%;
  width: 100%;
  overflow: hidden;
  .mnavItems {
    display: none;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    transform: translateY(-100%);
    transition: 1s transform ease;
    background-color: teal;
    padding: 20px 0;
    width: 100%;
    @include tablet {
      display: flex;
    }
    &.active {
      transform: translateY(0);
    }
    .menuItem {
      display: flex;
      align-items: center;
      font-weight: 550;
      font-size: 1.2em;
      font-weight: 600;
      position: relative;
      transition: 0.5s all ease;
      color: white;
      text-transform: uppercase;
      color: orange;
      &:hover {
        color: rgb(80, 78, 78);
        cursor: pointer;
      }
      .morePro {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        .mpContainer {
          display: flex;
          margin-top: 37px;
          width: 30rem;
          height: 200px;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 5px;
          border-end-end-radius: 6px;
          border-end-start-radius: 6px;
          .right {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-around;
            // gap: 0px0;
            border-left: 2px solid gray;
            .mpItem {
              width: 100px;
              font-size: 0.9em;
              color: orange;
              margin-right: 20px;
              font-weight: 600;
              padding: 5px 10px;
              border-radius: 6px;
              transition: 0.3s all ease;
              &:hover {
                background-color: white;
              }
            }
          }
          .left {
            flex: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}
