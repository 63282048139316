@import "../../../style/mixin";
@import "../../../style/responsive";

.orderItem {
  border: 1px solid lightgray;
  border-radius: 6px;
  @include shadowItem;
  padding: 5px;
  .orderContainer {
    padding: 10px;
    @include center-h;
    &.active {
      font-weight: bold;
      font-size: large;
    }
    .date {
      @include center-h;
      gap: 10px;
      @include mobile {
        display: none;
      }
    }
    .sendStatus {
      margin-right: 20px;
      color: orange;
      @include mobile {
        margin-right: 0;
      }
      &.succ {
        color: green;
      }
    }
    .status {
      margin-right: auto;
      margin-left: 15px;
      @include center-v;
      // align-items: center;
      gap: 10px;
      :nth-child(1) {
        font-size: 0.9em;
      }
      :nth-child(2) {
        // font-weight: bold;
        &.succ {
          color: green;
        }
        &.fail {
          color: red;
        }
      }
    }
    .icon {
      cursor: pointer;
    }
  }
  .detail {
    margin: 10px;
    margin-top: 0;
    border-top: 2px solid lightblue;
    padding: 10px 0;
    .headre {
      display: flex;
      align-items: center;
      gap: 10px;
      .total {
        margin-right: auto;
        @include center-h;
        gap: 5px;
      }
      .tracking {
        @include center;
        gap: 5px;
        span {
          @include mobile {
            display: none;
          }
        }
        h4 {
          font-size: 0.8em;
        }
      }
      .del {
        color: red;
        cursor: pointer;
      }
    }
    .producst {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 15px;
      .pItem {
        padding: 10px;
        @include center-h;
        border-bottom: 1px solid lightgray;
        gap: 20px;
        @include mobile {
          padding: 10px 0px;
        }
        .dis {
          background-color: red;
          color: white;
          border-radius: 10px;
          padding: 2px 8px;
          @include center;
          @include mobile {
            height: max-content;
            font-size: 0.8em;
          }
        }
        .sum {
          margin-right: auto;
          @include mobile {
            font-size: 0.8em;
          }
        }
        .price {
          @include mobile {
            font-size: 0.8em;
          }
        }
        .name {
          @include mobile {
            font-size: 0.8em;
          }
        }
        .count {
          @include mobile {
            font-size: 0.8em;
          }
        }
      }
    }
    .ticketBtn {
      width: max-content;
      padding: 5px 10px;
      background-color: teal;
      color: white;
      border-radius: 10px;
      margin-right: auto;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}

//LTR DIRECTION STYLE
.orderItem-ltr {
  border: 1px solid lightgray;
  border-radius: 6px;
  @include shadowItem;
  padding: 5px;
  .orderContainer {
    padding: 10px;
    direction: ltr;
    @include center-h;
    &.active {
      font-weight: bold;
      font-size: large;
    }
    .date {
      @include center-h;
      gap: 10px;
      @include mobile {
        display: none;
      }
    }
    .sendStatus {
      margin-right: 20px;
      color: orange;
      @include mobile {
        margin-right: 0;
      }
      &.succ {
        color: green;
      }
    }
    .status {
      direction: ltr;
      margin-left: auto;
      margin-right: 15px;
      @include center-v;
      gap: 10px;
      :nth-child(1) {
        font-size: 0.9em;
      }
      :nth-child(2) {
        &.succ {
          color: green;
        }
        &.fail {
          color: red;
        }
      }
    }
    .icon {
      cursor: pointer;
    }
  }
  .detail {
    margin: 10px;
    margin-top: 0;
    border-top: 2px solid lightblue;
    padding: 10px 0;
    .headre {
      display: flex;
      align-items: center;
      gap: 10px;
      direction: ltr;
      .total {
        direction: ltr;
        margin-left: auto;
        @include center-h;
        gap: 5px;
      }
      .tracking {
        direction: ltr;
        @include center;
        gap: 5px;
        span {
          @include mobile {
            display: none;
          }
        }
        h4 {
          font-size: 0.8em;
        }
      }
      .del {
        color: red;
        cursor: pointer;
      }
    }
    .producst {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 15px;
      .pItem {
        padding: 10px;
        @include center-h;
        border-bottom: 1px solid lightgray;
        gap: 20px;
        direction: ltr;
        @include mobile {
          padding: 10px 0px;
        }
        .dis {
          background-color: red;
          color: white;
          border-radius: 10px;
          padding: 2px 8px;
          @include center;
          @include mobile {
            height: max-content;
            font-size: 0.8em;
          }
        }
        .sum {
          margin-left: auto;
          direction: ltr;
          @include mobile {
            font-size: 0.8em;
          }
        }
        .price {
          direction: ltr;
          @include mobile {
            font-size: 0.8em;
          }
        }
        .name {
          @include mobile {
            font-size: 0.8em;
          }
        }
        .count {
          direction: ltr;
          @include mobile {
            font-size: 0.8em;
          }
        }
      }
    }
    .ticketBtn {
      width: max-content;
      padding: 5px 10px;
      background-color: teal;
      color: white;
      border-radius: 10px;
      margin-left: auto;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
