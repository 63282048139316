@import "../../../style/mixin";
@import "../../../style/responsive";

.ticket {
  @include shadowItem;
  border-radius: 10px;
  // height: 500px;
  .header {
    border-bottom: 0.5px solid lightgray;
    @include center-v;
    justify-content: space-between;
    height: 50px;
    padding: 10px;
  }
  .messages {
    background-color: #dcdbdb;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    height: 400px;
    overflow-y: scroll;
    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }
    .you {
      background-color: green;
      max-width: 60%;
      width: max-content;
      min-width: 10%;
      border-radius: 10px;
      margin-right: auto;
      padding: 10px;
      color: white;
      text-align: right;
      .time {
        text-align: left;
        margin-top: 5px;
        font-size: 0.8em;
        color: rgb(215, 210, 210);
      }
    }
    .support {
      padding: 10px;
      max-width: 60%;
      width: max-content;
      min-width: 10%;
      border-radius: 10px;
      background-color: white;
      margin-left: auto;
      margin-right: none;
      text-align: right;
      .time {
        text-align: left;
        margin-top: 5px;
        font-size: 0.8em;
        color: rgb(148, 145, 145);
      }
    }
  }
  .sendeBox {
    display: flex;
    align-items: center;
    height: 60px;
    border-top: 0.5px solid lightblue;
    gap: 10px;
    input {
      height: 100%;
      padding: 10px;
      font-size: 1.1em;
      flex: 6;
      outline: none;
      border: none;
    }
    .sendBtn {
      flex: 1;
    }
  }
}

//LTR DIRECTION STYLES
.ticket-ltr {
  @include shadowItem;
  border-radius: 10px;
}
