@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin center-h() {
  display: flex;
  justify-content: center;
}

@mixin center-v() {
  display: flex;
  align-items: center;
}

@mixin page-Width {
  width: 100%;
  display: flex;
  justify-content: center;
}

@mixin maxWidth($max) {
  width: 100%;
  max-width: $max;
}

@mixin subtitle {
  font-size: $subtitle-size;
  color: $subtitle-color;
  margin: 15px 0;
}

@mixin title {
  font-size: $title-size;
  color: $title-color;
  margin: 20px 0;
}

@mixin para {
  font-size: $text-size;
  color: $text-color;
  margin-bottom: 15px;
  font-size: 1.2em;
}

//font size

$title-size: 2em;
$subtitle-size: 1.7em;
$text-size: 1.1em;
$list-size: 1.1em;

//color
$title-color: orange;
$subtitle-color: orange;
$text-color: rgba(0, 0, 0, 0.757);
$list-color: rgba(0, 0, 0, 0.886);

@mixin shadow {
  -webkit-box-shadow: 2px 9px 29px 0px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 2px 9px 29px 0px rgba(0, 0, 0, 0.44);
  box-shadow: 2px 9px 29px 0px rgba(0, 0, 0, 0.44);
}

@mixin shadowItem {
  -webkit-box-shadow: 2px 1px 11px -3px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 1px 11px -3px rgba(66, 68, 90, 1);
  box-shadow: 2px 1px 11px -3px rgba(66, 68, 90, 1);
}
