@import "../../style/responsive";
@import "../../style/mixin";

.recipeOne {
  width: 100%;
  display: flex;
  justify-content: center;
  background: url("../../../public/images/simple-background.jpg");
  .roContainer {
    width: 100%;
    max-width: 80%;
    @include large {
      @include maxWidth(80%);
    }
    @include tablet {
      @include maxWidth(85%);
    }
    @include mobile {
      @include maxWidth(95%);
    }
    .searchBox {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 50px 0px;
      @include large {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
      .title {
        color: purple;
        font-weight: 600;
        font-size: 1.5em;
      }
      .search {
        display: flex;
        width: 40%;
        align-items: center;
        justify-content: center;
        gap: 15px;
        text-align: center;
        @include large {
          width: 60%;
        }
        @include tablet {
          width: 90%;
        }
        @include mobile {
          width: 100%;
        }
        input {
          width: 100%;
          padding: 10px;
          font-size: 0.9em;
        }
        .icon {
          color: orange;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }
      .filters {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        @include large {
          width: 100%;
          gap: 5px;
        }
        .filter {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border-left: 1px solid gray;
          padding: 5px 10px;
          position: relative;
          .titr {
            font-size: 1.2em;
            font-weight: 600;
            cursor: pointer;
            @include large {
              font-size: 1em;
              font-weight: 600;
            }
          }
          .icon {
            cursor: pointer;
            z-index: 100;
          }
          .meals {
            position: absolute;
            top: 100%;
            right: 0;
            width: 100%;
            overflow: hidden;
            z-index: 1000;
            .mealContainer {
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding: 10px;
              transform: translateY(-100%);
              background-color: white;
              transition: 0.5s transform ease;
              border-radius: 6px;
              &.active {
                transform: translateY(0);
              }
              .meal {
                text-align: right;
                padding: 0px 5px;
                border-radius: 6px;
                cursor: pointer;
                font-weight: 500;
                color: gray;
                &:hover {
                  background-color: lightgray;
                }
              }
            }
          }
        }
      }
    }
  }
}

//LTR DIRECTION STYLES
.recipeOne-ltr {
  width: 100%;
  display: flex;
  justify-content: center;
  background: url("../../../public/images/simple-background.jpg");
  .roContainer {
    width: 100%;
    max-width: 80%;
    .searchBox {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 50px 0px;
      direction: ltr;
      text-transform: uppercase;
      .title {
        color: purple;
        font-weight: 600;
        font-size: 1.5em;
      }
      .search {
        display: flex;
        width: 35%;
        align-items: center;
        justify-content: center;
        gap: 15px;
        // margin: 30px;
        text-align: center;

        input {
          width: 100%;
          padding: 10px;
          font-size: 0.9em;
          direction: ltr;
        }
        .icon {
          color: orange;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }
      .filters {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        direction: ltr;
        .filter {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border-left: 1px solid gray;
          padding: 5px 10px;
          position: relative;
          .titr {
            font-size: 1.2em;
            font-weight: 500;
            cursor: pointer;
          }
          .icon {
            cursor: pointer;
            z-index: 100;
          }
          .meals {
            position: absolute;
            top: 100%;
            right: 0;
            width: 100%;
            overflow: hidden;
            z-index: 1000;
            .mealContainer {
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding: 10px;
              transform: translateY(-100%);
              background-color: white;
              transition: 0.5s transform ease;
              border-radius: 6px;
              &.active {
                transform: translateY(0);
              }
              .meal {
                text-align: left;

                padding: 0px 5px;
                border-radius: 6px;
                cursor: pointer;
                font-weight: 500;
                color: gray;
                &:hover {
                  background-color: lightgray;
                }
              }
            }
          }
        }
      }
    }
  }
}
