@import "../../../style/mixin";
@import "../../../style/responsive";

.tickets {
  display: flex;
  flex-direction: column;
  gap: 15px;
  @include shadowItem;
  padding: 15px;
  h1 {
    text-align: right;
    color: rgb(67, 66, 66);
    font-size: 1.5em;
    &.ltr {
      text-align: left;
    }
  }
  .ticketsContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 450px;
    padding: 10px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
