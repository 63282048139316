@import "../../../style/mixin";
@import "../../../style/responsive";

.prof {
  @include shadowItem;
  border-radius: 10px;
  .profContainer {
    padding: 20px;
    h3 {
      margin-bottom: 15px;
      font-size: 1.5em;
      text-align: right;
      margin-bottom: 30px;
    }
    .profContent {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .top {
        display: flex;
        gap: 20px;
        img {
          width: 200px;
          height: 200px;
          text-align: right;
          border: 1px solid lightgray;
        }
        .selectImg {
          margin-top: auto;
          label {
            display: flex;
            gap: 10px;
            align-items: center;
          }
        }
      }
      .bottom {
        h4 {
          text-align: right;
          color: rgb(63, 62, 62);
          margin-bottom: 20px;
        }
        .details {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .detItem {
            @extend .details;
            text-align: right;
            input {
              width: 70%;
              padding: 10px;
              border-radius: 6px;
              border: 1px solid teal;
              outline-color: blue;
              @include mobile {
                width: 100%;
              }
            }
          }
        }
        .editBtn {
          background-color: teal;
          border: 1px solid white;
          color: white;
          width: 15%;
          margin-right: auto;
          margin-top: 20px;
          border-radius: 6px;
          padding: 8px 10px;
          // font-weight: bold;
          cursor: pointer;
          @include tablet {
            width: 20%;
          }
          @include mobile {
            width: 60%;
            margin: auto;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

//LTR DIRECTION STYLES
.prof-ltr {
  @include shadowItem;
  border-radius: 10px;
  .profContainer {
    padding: 20px;
    h3 {
      margin-bottom: 15px;
      font-size: 1.5em;
      text-align: left;
      margin-bottom: 30px;
    }
    .profContent {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .top {
        display: flex;
        gap: 20px;
        direction: ltr;
        img {
          width: 200px;
          height: 200px;
          text-align: left;
          border: 1px solid lightgray;
        }
        .selectImg {
          margin-top: auto;
          label {
            display: flex;
            gap: 10px;
            align-items: center;
          }
        }
      }
      .bottom {
        h4 {
          text-align: left;
          color: rgb(63, 62, 62);
          margin-bottom: 20px;
        }
        .details {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .detItem {
            @extend .details;
            text-align: left;
            direction: ltr;
            input {
              width: 70%;
              padding: 10px;
              border-radius: 6px;
              border: 1px solid teal;
              outline-color: blue;
              direction: ltr;
              @include mobile {
                width: 100%;
              }
            }
          }
        }
        .editBtn {
          background-color: teal;
          border: 1px solid white;
          color: white;
          width: 15%;
          margin-left: auto;
          margin-top: 20px;
          border-radius: 6px;
          padding: 8px 10px;
          // font-weight: bold;
          cursor: pointer;
          @include tablet {
            width: 20%;
          }
          @include mobile {
            width: 60%;
            margin: auto;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
