@import "../../style/mixin";
@import "../../style/responsive";

.blogCart {
  width: 400px;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  .details {
    background-color: orange;
    padding-bottom: 10px;
    p {
      text-align: justify;
      padding: 10px;
    }
    .seemore {
      margin: 10px;
      margin-bottom: 0;
      margin-right: auto;
      color: blue;
      background-color: lightblue;
      padding: 5px 10px;
      border-radius: 6px;
      width: max-content;
      cursor: pointer;
    }
  }
}
