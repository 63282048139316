@import "../../style/mixin";
@import "../../style/responsive";

.rHead {
  width: 100%;
  height: 40vh;
  position: relative;
  text-transform: uppercase;
  @include mobile {
    height: 30vh;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include tablet {
      object-fit: cover;
    }
  }
  .moreDetail {
    display: flex;
    position: absolute;
    width: 20%;
    height: 100%;
    right: 20%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    @include large {
      width: 30%;
    }
    .content {
      margin: auto;
      margin-right: 3%;
      // background-color: red;
      .title {
        color: white;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 3em;
        @include large {
          font-size: 2em;
        }
        @include mobile {
          font-size: 1.5em;
        }
      }
      .more-btn {
        margin-top: 40px;
        color: white;
        background-color: purple;
        padding: 10px;
        border-radius: 6px;
        cursor: pointer;
        width: max-content;
        padding: 8px 20px;
        @include tablet {
          font-size: 0.9em;
          padding: 5px;
        }
      }
    }
  }
}
