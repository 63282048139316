@import "../../style/mixin";
@import "../../style/responsive";

.menu {
  width: 100%;
  @include center;
  direction: ltr;
  height: 70px;
  background-color: rgb(247, 184, 68);
  @include tablet {
    height: 60px;
  }
  @include mobile {
    height: 50px;
  }
  .menuContainer {
    @include center-v;
    width: 100%;
    max-width: 80%;
    direction: ltr;
    @include large {
      max-width: 95%;
    }
    .cart {
      position: relative;
      margin-right: auto;
      .cartCounter {
        @include center;
        width: 17px;
        height: 17px;
        position: absolute;
        top: -8px;
        right: -12px;
        background-color: white;
        border-radius: 50%;
      }
      .cart-icon {
        cursor: pointer;
        @include mobile {
          width: 20px;
          height: 20px;
        }
      }
    }
    .moreMenu {
      display: none;
      color: white;
      cursor: pointer;
      @include tablet {
        display: flex;
        width: 40px;
        height: 40px;
      }
      @include mobile {
        width: 25px;
        height: 25px;
      }
    }
    .items {
      @include center-v;
      gap: 25px;
      margin-right: auto;
      @include tablet {
        display: none;
      }
      .menuItem {
        display: flex;
        align-items: center;
        font-size: 1.2em;
        font-weight: 600;
        position: relative;
        transition: 0.5s all ease;
        color: white;
        text-transform: uppercase;
        &:hover {
          color: rgb(80, 78, 78);
          cursor: pointer;
        }
        @include large {
          font-size: 1em;
        }
      }
    }
  }
}

//DIRECTION LTR STYLED
.menu-ltr {
  @include center;
  width: 100%;
  direction: ltr;
  background-color: rgb(247, 184, 68);
  height: 70px;
  cursor: pointer;
  @include tablet {
    height: 60px;
  }
  @include mobile {
    height: 50px;
  }
  .menuContainer {
    @include center-v;
    width: 100%;
    max-width: 80%;
    direction: ltr;
    // position: relative;
    height: 100%;
    @include large {
      max-width: 95%;
    }
    .cart {
      position: relative;
      margin-right: auto;
      .cartCounter {
        @include center;
        width: 17px;
        height: 17px;
        position: absolute;
        top: -8px;
        right: -12px;
        background-color: white;
        border-radius: 50%;
      }
      .cart-icon {
        cursor: pointer;
        @include mobile {
          width: 20px;
          height: 20px;
        }
      }
    }
    .moreMenu {
      display: none;
      color: white;
      @include tablet {
        display: flex;
        width: 40px;
        height: 40px;
      }
      @include mobile {
        width: 25px;
        height: 25px;
      }
    }
    .items {
      display: flex;
      align-items: center;
      gap: 25px;
      margin-right: auto;
      direction: ltr;
      @include tablet {
        display: none;
      }
      .menuItem {
        @include center-v;
        font-weight: 550;
        font-size: 1em;
        font-weight: 600;
        position: relative;
        transition: 0.5s all ease;
        color: white;
        text-transform: uppercase;
        &:hover {
          color: rgb(80, 78, 78);
          cursor: pointer;
        }
        @include large {
          font-size: 0.8em;
        }
        @include tablet {
          color: orange;
        }
        .morePro {
          display: none;
          position: absolute;
          top: 100%;
          right: 0;
          .mpContainer {
            display: flex;
            margin-top: 37px;
            width: 30rem;
            height: 200px;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            border-end-end-radius: 6px;
            border-end-start-radius: 6px;
            .right {
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: space-around;
              // gap: 0px0;
              border-left: 2px solid gray;
              .mpItem {
                width: 100px;
                font-size: 0.9em;
                color: orange;
                margin-right: 20px;
                font-weight: 600;
                padding: 5px 10px;
                border-radius: 6px;
                transition: 0.3s all ease;
                &:hover {
                  background-color: white;
                }
              }
            }
            .left {
              flex: 2;
              @include center;
              margin-right: 10px;
              img {
                width: 180px;
                height: 180px;
              }
            }
          }
        }
      }
    }
  }
}
