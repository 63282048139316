@import "../../style/mixin";
@import "../../style/responsive";

.locator {
  height: calc(100vh - 100px);
  .leaflet-container {
    width: 100%;
    height: 100%;
    .cluster-icon {
      transform: translate(25%, -25%);
      width: 40px;
      height: 40px;
      background-color: orange;
      color: black;
      font-weight: bold;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5em;
    }
  }
  .theCountries {
    position: fixed;
    z-index: 900;
    top: 480px;
    left: 5%;
    bottom: 40%;
    margin: auto;
    width: 20%;
    height: max-content;
    max-height: 60vh;
    overflow-y: scroll;
    @include full {
      @include maxWidth(30%);
    }
    @include tablet {
      @include maxWidth(45%);
    }
    @include mobile {
      @include maxWidth(60%);
      max-height: 40vh;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    background-color: orange;
    border-radius: 6px;
    .cContainer {
      padding: 15px 10px;
      .title {
        font-weight: 600;
        font-size: 1.5em;
        color: rgb(77, 73, 73);
        margin-bottom: 20px;
      }
      .selectItem {
        text-align: right;
        padding: 10px;
        text-align: left;
        h3 {
          margin-bottom: 10px;
        }
        select {
          width: 100%;
          padding: 5px 10px;
          font-size: large;
          // text-align: left;
        }
      }
    }
  }
}

//LTR STYLES
.locator-ltr {
  height: calc(100vh - 100px);
  .leaflet-container {
    width: 100%;
    height: 100%;
    .cluster-icon {
      transform: translate(25%, -25%);
      width: 40px;
      height: 40px;
      background-color: orange;
      color: black;
      font-weight: bold;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5em;
    }
  }
  .theCountries {
    position: fixed;
    z-index: 900;
    top: 480px;
    left: 5%;
    bottom: 40%;
    margin: auto;
    width: 20%;
    height: max-content;
    max-height: 60vh;
    overflow-y: scroll;
    @include full {
      @include maxWidth(30%);
    }
    @include tablet {
      @include maxWidth(45%);
    }
    @include mobile {
      @include maxWidth(60%);
      max-height: 40vh;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    background-color: orange;
    border-radius: 6px;
    .cContainer {
      padding: 15px 10px;
      .title {
        font-weight: 600;
        font-size: 1.5em;
        color: rgb(77, 73, 73);
        margin-bottom: 20px;
      }
      .selectItem {
        text-align: right;
        padding: 10px;
        text-align: left;
        h3 {
          margin-bottom: 10px;
        }
        select {
          width: 100%;
          padding: 5px 10px;
          font-size: large;
          // text-align: left;
        }
      }
    }
  }
}
