@import "../../style/responsive";
@import "../../style/mixin";

.banner {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  .bannerSlider {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    // animation: 7s slider reverse;
    transition: 3s all ease-in-out;
    background-position: center;
    animation-play-state: running;
    animation-iteration-count: infinite;
    position: relative;
    .texts {
      position: absolute;
      bottom: 10px;
      right: 20px;
      margin: auto;
      width: max-content;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include tablet {
        max-width: 300px;
        text-align: center;
      }

      &.ltr {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      h1 {
        color: green;
        font-size: 5em;
        font-weight: bold;
        text-transform: uppercase;
        @include large {
          font-size: 3.5em;
        }
        @include tablet {
          font-size: 3em;
          text-align: center;
          font-weight: bolder;
        }
        @include mobile {
          font-style: 2.8em;
        }
      }
      h3 {
        font-size: 3em;
        color: white;
      }
    }
  }
}

@keyframes slider {
  25% {
    background: url("../../../public/images/slider1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  50% {
    background: url("../../../public/images/slider3.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  75% {
    background: url("../../../public/images/slider2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  100% {
    background: url("../../../public/images/slider1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
