@import "../../style/mixin";
@import "../../style/responsive";

.cartItem {
  display: flex;
  align-items: center;
  height: 100px;
  background-color: white;
  margin: 10px 5px;
  border-radius: 6px;
  padding: 0 0 0 10px;
  gap: 5px;
  .itemImg {
    flex: 1;
    height: 90%;
    border-radius: 6px;
    overflow: hidden;
    @include center;
    justify-content: space-between;
    border-left: 0.5px solid lightgray;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .itemDetails {
    flex: 4;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    .top {
      @include center;
      justify-content: space-between;
      .pname {
        color: orange;
        text-align: right;
      }
      .discount {
        color: white;
        background-color: red;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 0.7em;
        @include center;
        margin-left: auto;
        margin-right: 15px;
        font-weight: 600;
        @include mobile {
          margin-right: 10px;
        }
      }
      .icon {
        color: red;
        cursor: pointer;
        &:hover {
          color: rgb(176, 70, 70);
        }
      }
    }
    .bottom {
      @include center;
      justify-content: space-between;
      .price {
        color: green;
        font-size: 16px;
        @include mobile {
          font-size: 1em;
        }
      }
      .amount {
        margin-left: auto;
        margin-right: 15px;
        @include mobile {
          margin-right: 10px;
        }
      }
      .btns {
        @include center;
        background-color: green;
        color: white;
        padding: 5px;
        gap: 10px;
        border-radius: 6px;
        .icon {
          cursor: pointer;
          &:hover {
            color: lightgray;
          }
        }
      }
    }
  }
}

//LTR DIRECTION STYLES
.cartItem-ltr {
  display: flex;
  align-items: center;
  height: 100px;
  background-color: white;
  margin: 10px 5px;
  border-radius: 6px;
  padding: 0 10px 0 0;
  gap: 5px;
  direction: ltr;
  .itemImg {
    flex: 1;
    height: 90%;
    border-radius: 6px;
    overflow: hidden;
    @include center;
    border-right: 0.5px solid lightgray;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .itemDetails {
    flex: 4;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    .top {
      @include center;
      justify-content: space-between;
      direction: ltr;
      .pname {
        color: orange;
        text-align: left;
      }
      .discount {
        color: white;
        background-color: red;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 0.7em;
        @include center;
        margin-right: auto;
        margin-left: 15px;
        font-weight: 600;
        @include mobile {
          margin-left: 10px;
        }
      }
      .icon {
        color: red;
        cursor: pointer;
        &:hover {
          color: rgb(176, 70, 70);
        }
      }
    }
    .bottom {
      @include center;
      justify-content: space-between;
      direction: ltr;
      .price {
        color: green;
        font-size: 16px;
        @include mobile {
          margin-left: 1em;
        }
      }
      .amount {
        margin-right: auto;
        margin-left: 15px;
        @include mobile {
          margin-left: 10px;
        }
      }
      .btns {
        @include center;
        background-color: green;
        color: white;
        padding: 5px;
        gap: 10px;
        border-radius: 6px;
        .icon {
          cursor: pointer;
          &:hover {
            color: lightgray;
          }
        }
      }
    }
  }
}
