@import "../../style/mixin";
@import "../../style/responsive";

.recipesMost {
  width: 100%;
  @include center;
  flex-direction: column;
  margin-bottom: 50px;
  margin-top: 30px;
  text-transform: uppercase;
  .rvContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    @include maxWidth(80%);
    @include large {
      @include maxWidth(90%);
    }
    @include tablet {
      // flex-direction: column;
      gap: 20px;
    }
    .mostItem {
      width: 250px;
      display: flex;
      flex-direction: column;
      .headreItem {
        font-weight: 500;
        font-size: 1.2em;
        margin-bottom: 15px;
      }
    }
  }
}
