@import "../../style/responsive";

.aboutHoney {
  width: 100%;
  display: flex;
  justify-content: center;
  .aboutHoneyContainer {
    width: 100%;
    max-width: 60%;
    @include large {
      max-width: 90%;
    }
    @include tablet {
      max-width: 95%;
      padding: 20px;
    }
    @include mobile {
      flex-direction: column;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 20px;
    .img {
      flex: 1;
      @include tablet {
        flex: 2;
      }
      img {
        width: 100%;
      }
    }
    .text {
      flex: 1.5;
      @include tablet {
        flex: 1;
      }
      .title {
        font-weight: 600;
        font-size: 1.5em;
        @include tablet {
          font-size: 1em;
        }
      }
      h1 {
        color: orange;
        font-size: 2.5em;
        margin: 10px 0px;
        @include tablet {
          font-size: 2em;
        }
      }
      p {
        font-weight: 500;
        font-size: 1.2em;
        text-align: center;
      }
      .btn {
        cursor: pointer;
        background-color: orange;
        width: 200px;
        padding: 10px;
        border-radius: 6px;
        position: relative;
        margin: auto;
        margin-top: 20px;
        color: white;
        font-size: 1.1em;
        @include tablet {
          width: 100%;
        }
        .arrow-icon {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 50px;
          transition: 0.4s all ease;
        }
        &:hover {
          .arrow-icon {
            right: 40px;
            color: lightgray;
          }
        }
        span {
          text-transform: capitalize;
        }
      }
    }
  }
}
