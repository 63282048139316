.blogHistory {
  flex: 1;
  background-color: rgb(72, 111, 72);
  border-radius: 10px;
  h1 {
    font-size: 3em;
    color: white;
    text-align: right;
    margin: 15px 20px;
  }
  .historyContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    span {
      color: rgb(68, 64, 64);
      font-weight: bold;
      text-align: right;
      margin: 0 20px;
      font-size: 1.3em;
      cursor: not-allowed;
      &.bold {
        color: black;
        cursor: pointer;
        &:hover {
          color: orange;
        }
      }
      &.active {
        color: orange;
        font-size: 1.5em;
      }
    }
    .yearCon {
      .year {
        color: black;
        font-size: 3em;
        cursor: pointer;
        &:hover {
          color: orange;
        }
      }
      .icon {
        color: rgb(72, 111, 72);
        border-radius: 50%;
        background-color: black;
        cursor: pointer;
        &:hover {
          background-color: orange;
        }
      }
    }
  }
}
