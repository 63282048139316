@import "../../style/mixin";
@import "../../style/responsive";

.productCart {
  background-color: white;
  -webkit-box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 14px -1px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  .prcContainer {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    padding: 5px;
    width: 268px;
    @include tablet {
    }
    .imgContainer {
      width: 100%;
      height: 200px;
      position: relative;
      cursor: pointer;
      background-color: white;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px 6px 0 0;
        object-fit: cover;
      }
      .imgHover {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        transition: 1s all ease;
        .icons {
          display: flex;
          align-items: center;
          gap: 10px;
          z-index: 100;
        }
      }
      &:hover {
        .imgHover {
          display: flex;
        }
      }
      .dismark {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: red;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }
    }
    .details {
      background-color: orange;
      padding: 10px;
      padding-bottom: 5px;
      border-radius: 0 0 6px 6px;
      .name-price {
        display: flex;
        align-items: start;
        justify-content: space-between;
        height: 30px;
        .productname {
          font-size: 1em;
          flex: 2;
          text-align: right;
          height: 20px;
          @include mobile {
            font-size: 0.8em;
          }
        }
        .priceDetail {
          display: flex;
          align-items: center;
          justify-content: end;
          width: max-content;
          flex: 1;
          gap: 10px;
          @include mobile {
            font-size: 0.8em;
          }
          .price {
            color: green;
            font-weight: 600;
            font-size: 0.8em;
          }
          .dis {
            color: red;
            font-size: 1.1em;
            text-decoration: line-through;
          }
        }
      }
      .desc {
        width: 100%;
        height: 65px;
        margin: 10px 0;
        text-align: right;
        font-size: 1em;
        color: white;
      }
      .add-more {
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-top: 18px;
        .add {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: whitesmoke;
          width: 30px;
          height: 30px;
          transition: 0.5s all ease;
          border-radius: 50%;
          cursor: pointer;
          .icon {
            color: orange;
          }
          &:hover {
            width: 60px;
            border-radius: 6px;
          }
        }
        .seeMore-btn {
          color: gray;
          background-color: white;
          border-radius: 6px;
          font-size: 0.8em;
          padding: 2px 10px;
        }
      }
    }
  }
}
