@import "../../style/mixin";
@import "../../style/responsive";

.menuList {
  width: 100%;
  @include center-h;
  margin: 20px 0;
  align-items: center;
  .mlContainer {
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    @include maxWidth(60%);
    @include tablet {
      @include maxWidth(80%);
    }
    @include mobile {
      @include maxWidth(90%);
      padding-bottom: 5px;
    }
    box-shadow: 0px 11px 17px -2px rgba(0, 0, 0, 0.1);
    ul {
      display: flex;
      list-style: none;
      gap: 20px;
      margin: 0;
      @include tablet {
        flex-wrap: wrap;
        gap: 10px;
      }
      @include mobile {
        flex-wrap: wrap;
        gap: 5px;
      }
      li {
        font-size: 1.2em;
        font-weight: 600;
        color: $list-color;
        border-radius: 6px;
        padding: 15px;
        transition: 0.3s all ease;
        cursor: pointer;
        border: none;
        @include tablet {
          padding: 10px;
        }
        @include mobile {
          padding: 10px;
        }
        &:hover {
          background-color: orange;
          color: whitesmoke;
        }
        @include large {
          font-size: 0.9em;
        }
      }
    }
  }
}

//

.menuList-ltr {
  width: 100%;
  @include center-h;
  margin: 20px 0;
  align-items: center;
  .mlContainer {
    padding-left: 10px;
    padding-bottom: 10px;
    width: 100%;
    @include maxWidth(60%);
    @include tablet {
      @include maxWidth(80%);
    }
    @include mobile {
      @include maxWidth(90%);
      padding-bottom: 5px;
    }
    box-shadow: 0px 11px 17px -2px rgba(0, 0, 0, 0.1);
    ul {
      display: flex;
      list-style: none;
      gap: 20px;
      margin: 0;
      direction: ltr;
      @include tablet {
        flex-wrap: wrap;
        gap: 10px;
      }
      @include mobile {
        flex-wrap: wrap;
        gap: 5px;
      }
      li {
        font-size: 1em;
        font-weight: 600;
        color: $list-color;
        border-radius: 6px;
        padding: 15px;
        transition: 0.3s all ease;
        cursor: pointer;
        border: none;
        text-transform: uppercase;
        @include tablet {
          padding: 10px;
        }
        @include mobile {
          padding: 10px;
        }
        &:hover {
          background-color: orange;
          color: whitesmoke;
        }
        @include large {
          font-size: 0.9em;
        }
      }
    }
  }
}
