.anouncment {
  width: 100%;
  background-color: black;
  color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  span {
    font-weight: 600;
    margin-right: 5px;
    margin-left: 5px;
  }
  a {
    &:hover {
      font-weight: 1.2em;
      color: orange;
    }
  }
}
