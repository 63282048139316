@import "../../style/responsive";

.bees {
  display: flex;
  justify-content: center;
  align-items: center;
  // background: url("../../../public/images/background.jpg");
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-size: cover;
  background-position: center;
  position: relative;
  isolation: isolate;
  @include tablet {
    // max-height: 700px;
  }
  &::after {
    content: "";
    background-color: rgb(255, 166, 0);
    position: absolute;
    top: 0;
    inset: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: -1;
  }
  .textContainer {
    width: 50%;
    height: 50%;
    z-index: 100;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    @include tablet {
      width: 70%;
    }
    @include mobile {
      width: 90%;
    }
    .btn {
      cursor: pointer;
      background-color: brown;
      width: 250px;
      padding: 10px;
      border-radius: 6px;
      position: relative;
      margin-top: 20px;
      .arrow-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 50px;
        transition: 0.4s all ease;
      }
      &:hover {
        .arrow-icon {
          right: 40px;
          color: lightgray;
        }
      }
    }
    h2 {
      font-size: 3em;
      // font-size: clamp(15px, 2vw, 16px);
    }
    h1 {
      font-size: 5em;
    }
  }
}
