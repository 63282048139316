.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10002;
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    img {
      width: 100px;
      height: 100px;
    }
    .cirlcels {
      display: flex;
      align-items: center;
      gap: 10px;
      transition: 0.2s all ease;
      transform-origin: right;
      .cirItem {
        background-color: rgba(0, 0, 0, 0.6);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transition: 0.2s all ease;
        transform-origin: right;
        &.active {
          background-color: orange;
          //   transform: scale(1.3);
          width: 25px;
          border-radius: 10px;
        }
      }
    }
  }
}
