@import "../../style/mixin";
@import "../../style/responsive";

.supplierDetails {
  width: 100%;
  text-align: right;
  position: relative;
  .backicon {
    position: absolute;
    top: 0;
    left: 5px;
    cursor: pointer;

    &:hover {
      color: rgb(74, 72, 72);
    }
  }
  h3 {
    margin: 20px 0px;
    font-size: 1.5em;
  }

  .item {
    margin: 10px;
    padding: 10px 0;
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    span {
      color: rgb(80, 76, 76);
    }
  }
}

//LTR STYLES
.supplierDetails-ltr {
  width: 100%;
  text-align: left;
  position: relative;
  .backicon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    &:hover {
      color: rgb(74, 72, 72);
    }
  }
  h3 {
    margin: 20px 0px;
  }

  .item {
    margin: 10px;
    padding: 10px 0;
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    span {
      color: rgb(80, 76, 76);
    }
  }
}
