@import "../../style/responsive";
@import "../../style/mixin";

.login {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  overflow: hidden;
  .closeBtn {
    position: absolute;
    top: -30px;
    left: -10px;
    cursor: pointer;
  }
  .title {
    width: max-content;
    font-size: 1.5em;
    color: orange;
    margin: 20px auto;
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    label {
      display: flex;
      flex-direction: column;
      color: black;
      font-size: 16px;
      position: relative;
      @include tablet {
        font-size: 14px;
        font-weight: 550;
      }
      input {
        width: 500px;
        border: none;
        background-color: rgb(199, 224, 232);
        border-radius: 15px;
        padding: 5px 10px;
        outline: none;
        height: 60px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-family: "vazir";
        @include tablet {
          width: 400px;
        }
        @include mobile {
          width: 310px;
        }
      }
      .eye {
        position: absolute;
        top: 45%;
        left: 10px;
        margin: auto;
        z-index: 100;
        cursor: pointer;
      }
    }

    button {
      padding: 15px;
      margin-top: 30px;
      width: 100%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: white;
      cursor: pointer;
      font-family: "vazir";
      background-color: orange;
      z-index: 100;
      font-weight: 500;
      font-size: 1.2em;
      &:hover {
        background-color: rgba(255, 166, 0, 0.851);
      }
    }
    .forgetPass {
      color: blue;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .newAcc {
      text-align: center;
      font-size: 14px;
      small {
        color: blue;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

//LOGIN-LTR

.login-ltr {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  overflow: hidden;
  text-transform: capitalize;
  .closeBtn {
    position: absolute;
    top: -30px;
    left: -10px;
    cursor: pointer;
  }
  .title {
    width: max-content;
    font-size: 1.5em;
    color: orange;
    margin: 20px auto;
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    text-align: left;
    label {
      display: flex;
      flex-direction: column;
      color: black;
      font-size: 18px;
      position: relative;
      @include tablet {
        font-size: 16px;
      }
      input {
        width: 500px;
        border: none;
        background-color: rgb(199, 224, 232);
        border-radius: 15px;
        padding: 10px;
        outline: none;
        height: 60px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 16px;
        text-align: left;
        direction: ltr;
        @include tablet {
          width: 350px;
        }
        @include mobile {
          width: 310px;
        }
      }
      .eye {
        position: absolute;
        top: 45%;
        right: 10px;
        margin: auto;
        z-index: 100;
        cursor: pointer;
        width: max-content;
      }
    }
    button {
      padding: 15px;
      margin-top: 30px;
      width: 100%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: white;
      cursor: pointer;
      background-color: orange;
      z-index: 100;
      font-weight: 500;
      font-size: 1.2em;
      &:hover {
        background-color: rgba(255, 166, 0, 0.851);
      }
    }
    .forgetPass {
      color: blue;
      font-size: 13px;
      cursor: pointer;
      direction: ltr;
      text-transform: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .newAcc {
      text-align: center;
      font-size: 14px;
      text-transform: none;
      small {
        color: blue;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
