@import "../../style/responsive";
.us {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url("../../../public/images/daryanManu2.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 30px;
  .usContainer {
    width: 100%;
    max-width: 80%;
    margin: 50px 0px;
    @include tablet {
      max-width: 85%;
    }
    @include mobile {
      max-width: 100%;
    }
    .builderMan {
      width: 100%;
      border-radius: 6px;
      display: grid;
      gap: 30px;
      margin-bottom: 40px;
      .builderPic {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 6px;
        }
      }
      .builderDetails {
        h3 {
          text-align: right;
          color: orange;
          margin-bottom: 10px;
          font-size: clamp(16px, 2vw, 20px);
        }
        p {
          color: whitesmoke;
          text-align: justify;
          line-height: 35px;
          font-size: clamp(16px, 2vw, 18px);
        }
      }
    }
    .title {
      font-size: 2em;
      font-weight: 600;
      color: orange;
      width: max-content;
      margin-bottom: 20px;
    }
    .text {
      color: white;
      font-weight: 500;
      font-size: 1.5em;
      line-height: 40px;
      text-align: justify;
    }
    .subTitle {
      color: orange;
      font-size: 1.5em;
      width: max-content;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .contactDetails {
      margin: 30px 0;
      .cdItem {
        display: flex;
        align-items: center;
        gap: 15px;
        margin: 15px 0;
        .titleItem {
          color: gray;
          font-weight: 500;
          font-size: 1.3em;
          width: 20%;
          text-align: right;
        }
        .value {
          color: whitesmoke;
          font-size: 1.3em;
        }
      }
    }
  }
}

//US-LTR STYLES

.us-ltr {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("../../../public/images/daryanManu2.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 30px;
  .usContainer {
    width: 100%;
    max-width: 70%;
    // height: 80vh;
    margin: 50px 0px;
    direction: ltr;
    @include tablet {
      max-width: 85%;
    }
    @include mobile {
      max-width: 97%;
    }
    .builderMan {
      direction: ltr;
      width: 100%;
      border-radius: 6px;
      display: grid;
      gap: 30px;
      margin-bottom: 40px;
      .builderPic {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 6px;
        }
      }
      .builderDetails {
        h3 {
          text-align: left;
          color: orange;
          margin-bottom: 10px;
          font-size: clamp(16px, 2vw, 20px);
        }
        p {
          color: whitesmoke;
          text-align: left;
          line-height: 35px;
          font-size: clamp(16px, 2vw, 18px);
        }
      }
    }
    .title {
      font-size: 2em;
      font-weight: 600;
      color: orange;
      width: max-content;
      margin-bottom: 20px;
    }
    .text {
      color: white;
      font-weight: 500;
      font-size: 1.5em;
      line-height: 40px;
      text-align: left;
    }
    .subTitle {
      color: orange;
      font-size: 1.5em;
      width: max-content;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .contactDetails {
      margin: 30px 0;
      text-align: left;
      direction: ltr;
      .cdItem {
        width: max-content;
        display: flex;
        gap: 50px;
        margin: 15px 0;
        direction: ltr;
        .titleItem {
          color: gray;
          font-weight: 500;
          font-size: 1.3em;
          min-width: 10vw;
          text-align: left;
        }
        .value {
          color: whitesmoke;
          font-size: 1.3em;
        }
      }
    }
  }
}
