@import "../../style/responsive";

.footer {
  width: 100%;
  background-color: orange;
  display: flex;
  justify-content: center;
  .footerContainer {
    width: 100%;
    max-width: 90%;
    height: 100%;
    display: flex;
    gap: 30px;
    padding: 50px 10px;
    align-items: start;
    @include large {
      gap: 40px;
    }
    @include tablet {
      flex-direction: column;
    }
    .logoSection {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 15px;
      .logo {
        width: 40%;
        margin: auto;
        img {
          width: 100%;
          // padding: 15px;
        }
      }
      .contactDetails {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .contactItem {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          color: whitesmoke;
          font-weight: 500;
          font-size: 1.1em;
        }
      }
      .copyRight {
        font-weight: 500;
        font-size: 1.2em;
        color: black;
        margin: auto;
        text-align: right;
        @include large {
          text-align: center;
        }
      }
    }
    .links {
      flex: 1.5;
      .aboutCompany {
        text-align: right;
        color: white;
        margin-bottom: 15px;
        h3 {
          font-size: 1.5em;
          margin-bottom: 15px;
        }
        p {
          font-size: 1.08em;
          text-align: justify;
          max-width: 80%;
          line-height: 30px;
          .toabout {
            margin-right: 10px;
            font-size: 12px;
            color: lightblue;
            cursor: pointer;
          }
          @include mobile {
            max-width: 100%;
          }
        }
      }
      .socils {
        h3 {
          font-size: 1.5em;
          margin-bottom: 15px;
          width: max-content;
          color: whitesmoke;
        }
        .socialItems {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: auto;
          .socialIcon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.4);
            color: white;
            padding: 10px;
            transition: 0.5s transform ease;
            cursor: pointer;
            @include large {
              width: 30px;
              height: 30px;
              padding: 5px;
            }
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
      .approvals {
        text-align: center;
        margin: 20px 0;
        img {
          width: 130px;
          height: 130px;
        }
        .appTitle {
          text-align: right;
          margin: 15px 0;
          color: white;
          font-weight: 600;
          font-size: 1.5em;
        }
      }
    }
  }
}

//LTR DIRECTIONS
.footer-ltr {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: orange;
  // direction: ltr;
  .footerContainer {
    width: 100%;
    max-width: 90%;
    height: 100%;
    display: flex;
    gap: 100px;
    padding: 50px 10px;
    direction: ltr;
    align-items: start;
    @include large {
      gap: 40px;
    }
    @include tablet {
      flex-direction: column;
    }
    .logoSection {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      .logo {
        width: 40%;
        margin: auto;
        img {
          width: 100%;
          // padding: 15px;
        }
      }
      .contactDetails {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .contactItem {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          color: whitesmoke;
          font-weight: 500;
          font-size: 1.1em;
          direction: ltr;
        }
      }
      .copyRight {
        font-weight: 500;
        font-size: 1.2em;
        color: black;
        margin: auto;
        text-align: left;
        @include large {
          text-align: center;
        }
      }
    }
    .links {
      flex: 1.5;
      .aboutCompany {
        text-align: left;
        color: white;
        margin-bottom: 15px;
        h3 {
          font-size: 1.5em;
          margin-bottom: 15px;
        }
        p {
          font-size: 1.08em;
          text-align: left;
          max-width: 80%;
          line-height: 30px;
          margin-right: auto;
          @include mobile {
            max-width: 100%;
          }
          .toabout {
            margin-left: 10px;
            font-size: 12px;
            color: lightblue;
            cursor: pointer;
          }
        }
      }
      .socils {
        text-align: left;
        h3 {
          font-size: 1.5em;
          margin-bottom: 15px;
          width: max-content;
          color: whitesmoke;
          margin-right: auto;
        }
        .socialItems {
          display: flex;
          align-items: center;
          gap: 30px;
          justify-content: end;
          text-align: left;
          margin-top: 20px;
          .socialIcon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            padding: 10px;
            @include large {
              width: 30px;
              height: 30px;
              padding: 5px;
            }
            background-color: rgba(0, 0, 0, 0.4);
            color: white;
            transition: 0.5s transform ease;
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
      .approvals {
        text-align: left;
        img {
          width: 130px;
          height: 130px;
        }
        .appTitle {
          margin: 10px 0;
          color: white;
          font-weight: 600;
          font-size: 1.5em;
        }
      }
    }
  }
}
