@import "../../style/mixin";
@import "../../style/responsive";

.blogContent {
  width: 100%;
  @include center;
  background-image: url("../../../public/images/simple-background.jpg");
  padding: 30px;
  .bcContainer {
    width: 100%;
    max-width: 70%;
    // border: 2px solid lightgray;
    @include tablet {
      max-width: 90%;
    }
    @include tablet {
      max-width: 95%;
    }
    .title {
      font-size: 2.5em;
      font-weight: bold;
      color: orange;
      margin: 20px 0;
      text-align: right;
    }
    img {
      width: 700px;
      height: 400px;
      margin-left: auto;
      @include large {
        width: 500px;
      }
      @include tablet {
        width: 350px;
      }
      @include mobile {
        width: 300px;
        height: 200px;
      }
    }
    .blogtext {
      margin-top: 30px;
      .btextitem {
        h2 {
          color: black;
          font-size: 1.5em;
          text-align: right;
          margin-bottom: 15px;
        }
        p {
          font-size: 1.3em;
          color: rgb(73, 70, 70);
          text-align: right;
          margin-bottom: 20px;
        }
      }
    }
  }
}

//LTR DIRECTION STYLE
.blogContent-ltr {
  width: 100%;
  @include center;
  background-image: url("../../../public/images/simple-background.jpg");
  padding: 30px;
  .bcContainer {
    width: 100%;
    max-width: 70%;
    @include tablet {
      max-width: 90%;
    }
    @include tablet {
      max-width: 95%;
    }
    .title {
      font-size: 2.5em;
      font-weight: bold;
      color: orange;
      margin: 20px 0;
      text-align: left;
    }
    img {
      width: 700px;
      height: 400px;
      margin-left: auto;
      @include large {
        width: 500px;
      }
      @include tablet {
        width: 350px;
      }
      @include mobile {
        width: 300px;
        height: 200px;
      }
    }
    .blogtext {
      margin-top: 30px;
      .btextitem {
        h2 {
          color: black;
          font-size: 1.5em;
          text-align: left;
          margin-bottom: 15px;
        }
        p {
          font-size: 1.3em;
          color: rgb(73, 70, 70);
          text-align: left;
          margin-bottom: 20px;
        }
      }
    }
  }
}
