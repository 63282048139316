@import "../../style/mixin";
@import "../../style/responsive";

.changeLan {
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 5px;
  position: relative;
  span {
    text-transform: capitalize;
    color: teal;
  }
  .icon {
    cursor: pointer;
  }
  .langusges {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    z-index: calc(1);
    @include shadow;
    span {
      text-transform: capitalize;
      color: gray;
    }
    .lanItem {
      display: flex;
      align-items: center;
      gap: 5px;
      direction: ltr;
      cursor: pointer;
      font-size: 0.9em;
      margin: 5px;
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }
}
