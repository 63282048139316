@import "../../style/responsive";
@import "../../style/mixin";

.navbar {
  width: 100%;
  background-color: orange;
  @include center-v;
  flex-direction: column;
  // justify-content: center;
  top: -90px;
  z-index: 1;
  transition: 1s all ease-in-out;
  position: sticky;
  z-index: 101;
  top: -90px;
  @include mobile {
    top: -70px;
  }
  .navbarContainer {
    width: 100%;
    max-width: 90%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include large {
      max-width: 95%;
    }
    @include mobile {
      height: 70px;
    }
    .logo {
      width: 110px;
      height: 90px;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
      @include mobile {
        width: 80px;
        height: 60px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .searchBoxNav {
      @include center;
      gap: 10px;
      border: 1px solid white;
      border-radius: 6px;
      margin: auto;
      border: 2px solid lightblue;
      width: 40%;
      @include tablet {
        width: 90%;
        padding: 5px;
        border: none;
      }
      @include mobile {
        width: 100%;
      }
      &.ac {
        border: 1px solid lightblue;
      }
      input {
        display: flex;
        border: none;
        padding: 10px;
        width: 100%;
        transition: 1s all ease;
        outline: none;
        border-radius: 0 6px 6px 0;
        @include tablet {
          display: none;
        }
        &.ac {
          display: flex;
        }
      }
      .icon-search {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 10px;
        @include tablet {
          margin: 0;
        }
      }
      .removeinput {
        cursor: pointer;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 30px;
      .login-btn {
        width: max-content;
        border: 1px solid white;
        padding: 5px 10px;
        border-radius: 6px;
        cursor: pointer;
        color: black;
        background-color: white;
        font-weight: 500;
      }
      .userProfile {
        display: flex;
        align-items: end;
        gap: 5px;
        cursor: pointer;
        position: relative;
        img {
          width: 30px;
          height: 30px;
          border-radius: 6px;
          border: 0.5px solid white;
        }
        span {
          color: white;
          font-size: 0.9em;
          @include mobile {
            display: none;
          }
        }
        .showuserDetails {
          display: none;
          @include mobile {
            display: flex;
          }
        }
      }
    }
  }
}

//LTR sirection
.navbar-ltr {
  width: 100%;
  background-color: orange;
  display: flex;
  flex-direction: column;
  top: -90px;
  @include mobile {
    top: -70px;
  }
  z-index: 1;
  transition: 1s all ease-in-out;
  position: sticky;
  z-index: 101;
  //new change
  align-items: center;
  .navbarContainer {
    width: 100%;
    max-width: 90%;
    height: 90px;
    @include mobile {
      height: 70px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    @include large {
      max-width: 95%;
    }
    .logo {
      display: flex;
      width: 110px;
      height: 90px;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
      @include tablet {
        width: 70px;
        height: 60px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .searchBoxNav {
      @include center;
      border: 1px solid white;
      border-radius: 6px;
      border: 2px solid lightblue;
      //new ch
      margin: auto;
      width: 40%;
      @include tablet {
        width: 90%;
        padding: 5px;
        border: none;
      }
      &.ac {
        border: 1px solid lightblue;
      }
      //
      input {
        direction: ltr;
        border: none;
        padding: 10px;
        width: 100%;
        transition: 1s all ease;
        outline: none;
        border-radius: 0 6px 6px 0;
        height: 100%;
        @include tablet {
          display: none;
        }
        &.ac {
          display: flex;
        }
      }
      .icon-search {
        cursor: pointer;
        margin: 0 10px;
        @include tablet {
          margin: 5px;
        }
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 30px;
      .login-btn {
        width: max-content;
        border: 1px solid white;
        padding: 5px 10px;
        border-radius: 6px;
        cursor: pointer;
        color: black;
        background-color: white;
        font-weight: 500;
        @include tablet {
          font-size: 1em;
        }
      }
      .userProfile {
        display: flex;
        align-items: end;
        gap: 5px;
        cursor: pointer;
        position: relative;
        img {
          width: 30px;
          height: 30px;
          border-radius: 6px;
          border: 0.5px solid white;
        }
        span {
          color: white;
          font-size: 0.9em;
          @include mobile {
            display: none;
          }
        }
        .showuserDetails {
          display: none;
          @include mobile {
            display: flex;
          }
        }
      }
    }
  }
}
