@import "../../../style/mixin";
@import "../../../style/responsive";

.vallet {
  @include shadowItem;
  border: 1px solid lightgray;
  border-radius: 10px;
  .valContainer {
    padding: 20px;
    h3 {
      margin-bottom: 20px;
      font-size: 1.5em;
      text-align: right;
    }
    .increaseAmount {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 20px;
      .amount {
        h2 {
          color: gray;
        }
        h1 {
          color: green;
          margin: 15px 0;
          font-size: 2em;
        }
      }
      .increaseBox {
        h2 {
        }
        input {
          width: 50%;
          padding: 10px;
          text-align: center;
          margin: 15px 0;
          border-radius: 6px;
          border: 2px solid lightblue;
          outline-color: blue;
          font-size: 1.5em;
          @include tablet {
            width: 65%;
          }
          @include mobile {
            width: 70%;
            font-size: 1.2em;
          }
        }
      }
    }
    .changeBtn {
      width: max-content;
      background-color: teal;
      cursor: pointer;
      color: white;
      border-radius: 6px;
      padding: 7px 15px;
      margin-right: auto;
    }
  }
}
