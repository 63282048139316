@import "../../style/mixin";
@import "../../style/responsive";

.searchIem {
  display: flex;
  @include center-v;
  justify-content: space-between;
  background-color: #e7d37f;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  .imgDEtails {
    display: flex;
    gap: 15px;
    color: teal;
    color: white;
    cursor: pointer;
    transition: 0.3s all ease;
    &:hover {
      color: teal;
      img {
        border: 1px solid lightgray;
      }
    }
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      align-items: start;
      font-size: 1em;
      font-weight: bold;
    }
  }
  .add-dis {
    color: #81a263;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .status {
      border-radius: 10px;
      color: white;
      padding: 5px 10px;
      font-size: 0.7em;
      background-color: #40a578;
      &.no {
        background-color: red;
      }
    }
    .addsearchicon {
      transition: 0.3s all ease-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
        color: green;
      }
    }
  }
}

//LTR DIRECTION STYLES
.searchIem-ltr {
  display: flex;

  @include center-v;
  justify-content: space-between;
  background-color: #e7d37f;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  direction: ltr;
  .imgDEtails {
    display: flex;
    gap: 15px;
    color: white;
    direction: ltr;
    transition: 0.3s all ease;
    &:hover {
      color: teal;
      img {
        border: 1px solid lightgray;
      }
    }
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      align-items: end;
      font-size: 1em;
      font-weight: bold;
    }
  }
  .add-dis {
    color: #81a263;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    .status {
      border-radius: 10px;
      color: white;
      padding: 5px 10px;
      font-size: 0.7em;
      background-color: green;
      &.no {
        background-color: red;
      }
    }
    .addsearchicon {
      transition: 0.3s all ease-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
        color: green;
      }
    }
  }
}
