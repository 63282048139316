.recipesItem {
  width: 250px;
  background-color: white;
  -webkit-box-shadow: 10px 10px 5px -7px rgba(92, 82, 92, 1);
  -moz-box-shadow: 10px 10px 5px -7px rgba(92, 82, 92, 1);
  box-shadow: 10px 10px 5px -7px rgba(92, 82, 92, 1);
  .riContainer {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    padding: 5px;
    .imgContainer {
      width: 250px;
      height: 250px;
      position: relative;
      cursor: pointer;
      background-color: white;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .imgHover {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        transition: 1s all ease;
        .icons {
          display: flex;
          align-items: center;
          gap: 10px;
          z-index: 100;
        }
      }
      &:hover {
        .imgHover {
          display: flex;
        }
      }
    }
    .details {
      display: flex;
      background-color: white;
      padding: 10px;
      height: 150px;
      .title {
        width: max-content;
        margin-left: auto;
        margin-right: 5px;
        margin-top: 5px;
        color: rgb(72, 69, 69);
        font-weight: 600;
        font-size: 1.2em;
      }
      .seeMore-btn {
        margin-top: auto;
        margin-left: 10px;
        margin-bottom: 10px;
        color: teal;
        cursor: pointer;
        &:hover {
          color: rgb(5, 55, 55);
        }
      }
    }
  }
}

//LTR DIRECTION STYLE
.recipesItem-ltr {
  width: 250px;
  background-color: white;
  -webkit-box-shadow: 10px 10px 5px -7px rgba(92, 82, 92, 1);
  -moz-box-shadow: 10px 10px 5px -7px rgba(92, 82, 92, 1);
  box-shadow: 10px 10px 5px -7px rgba(92, 82, 92, 1);
  .riContainer {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    padding: 5px;
    .imgContainer {
      width: 250px;
      height: 250px;
      position: relative;
      cursor: pointer;
      background-color: white;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .imgHover {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        transition: 1s all ease;

        .icons {
          display: flex;
          align-items: center;
          gap: 10px;
          z-index: 100;
        }
      }
      &:hover {
        .imgHover {
          display: flex;
        }
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 10px;
      height: 150px;
      .title {
        width: max-content;
        margin-right: auto;
        margin-left: 5px;
        margin-top: 5px;
        color: rgb(72, 69, 69);
        font-weight: 600;
        font-size: 1.2em;
      }
      .seeMore-btn {
        margin-top: auto;
        margin-left: auto;
        width: max-content;
        margin-left: 10px;
        margin-bottom: 5px;
        color: teal;
        cursor: pointer;
        &:hover {
          color: rgb(5, 55, 55);
        }
      }
    }
  }
}
