@import "../../../style/mixin";
@import "../../../style/responsive";

.orders {
  border: 1px solid lightgray;
  border-radius: 10px;
  @include mobile {
    border: none;
  }
  .ordersContainer {
    padding: 20px;
    @include mobile {
      padding: 0;
    }
    h3 {
      margin-bottom: 15px;
      font-size: 1.5em;
    }
    .orderList {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
