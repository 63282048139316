@import "../../style/mixin";
@import "../../style/responsive";

.rightSide {
  flex: 1;
  @include shadowItem;
  // position: sticky;
  top: 90px;
  height: max-content;
  color: black;
  border-radius: 10px;
  @include tablet {
    position: inherit;
  }
  .rsContainer {
    padding: 20px 10px;
    .profile {
      padding: 0 10px;
      margin-bottom: 10px;
      .top {
        display: flex;
        flex-direction: column;
        gap: 10px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 0.5px solid lightgray;
          margin: auto;
          padding: 5px;
        }
        .details {
          .numBox {
            @include center-h;
            gap: 10px;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 1em;
            .icon {
              cursor: pointer;
            }
          }
          .userDetails {
            // @include shadow;
            border: 0.5px solid lightgray;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .udItem {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 600;
              font-size: 1em;
              :nth-child(1) {
                color: gray;
              }
            }
          }
        }
      }
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 20px;
      &.ltr {
        li {
          direction: ltr;
        }
      }
      li {
        text-align: left;
        padding: 10px;
        cursor: pointer;
        border-radius: 10px;
        transition: 0.2s all ease-in;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-weight: 600;
          font-size: 1em;
        }
        &:hover {
          background-color: lightblue;
        }
        &.active {
          background-color: lightblue;
        }
      }
    }
  }
}
