.comment {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  border-bottom: 0.5px solid lightgray;
  padding: 10px 0px;
  margin-right: 10px;
  width: 100%;
  .userProfile {
    flex: 1;
    display: flex;
    justify-content: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid lightgray;
    }
  }
  .commentContent {
    flex: 12;
    .user-name {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      .rtl {
        font-weight: normal;
        direction: ltr;
        margin-left: 10px;
      }
    }
    p {
      text-align: justify;
      padding-left: 30px;
      margin-top: 10px;
    }
  }
}

//LTR DIRECTION STYLE
.comment-ltr {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  border-bottom: 0.5px solid lightgray;
  padding: 10px 0px;
  direction: ltr;
  width: 100%;
  .userProfile {
    flex: 1;
    display: flex;
    justify-content: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid lightgray;
    }
  }
  .commentContent {
    flex: 12;
    text-align: left;
    .user-name {
      font-weight: bold;
      display: flex;
      direction: ltr;
      justify-content: space-between;
      .ltr {
        font-weight: normal;
        direction: ltr;
        margin-right: 10px;
      }
    }
    p {
      text-align: left;
      padding-right: 30px;
      margin-top: 10px;
    }
  }
}
