@import "../../style/mixin";

$margin_bottom_main: 15px;
$margin_bottom_sub: 10px;

.insideHive {
  @include center;
  flex-direction: column;
  .bannerContainer {
    width: 100%;
    height: 50vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .ihContainer {
    @include maxWidth(60%);
    .ihContent {
      text-align: right;
      .title {
        font-size: $title-size;
        color: $title-color;
        margin: 30px 0;
      }
      .subtitle {
        color: $subtitle-color;
        font-size: $subtitle-size;
        margin-bottom: $margin_bottom_sub;
      }
      .text {
        font-size: $text-size;
        color: $text-color;
        margin-bottom: $margin_bottom_main;
        line-height: 30px;
      }
      .items {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: $margin_bottom_main;
        img {
          width: 80px;
        }
      }
    }
  }
}
