@import "../../style/responsive";
@import "../../style/mixin";

.beekiping {
  width: 100%;
  display: flex;
  justify-content: center;
  .beekipingContainer {
    width: 100%;
    max-width: 60%;
    @include large {
      max-width: 90%;
      gap: 30px;
    }
    @include tablet {
      flex-direction: column;
    }
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 60px 0px;

    .img {
      flex: 1;
      @include tablet {
        order: 1;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      flex: 1;
      @include tablet {
        order: 2;
      }
      .title {
        font-size: 4em;
        font-weight: 600;
        color: green;
        margin-bottom: 20px;
        @include large {
          font-size: 2em;
        }
      }
      .textContent {
        font-size: 1.2em;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .beekiping-btn {
        background-color: olivedrab;
        padding: 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        width: 60%;
        margin: auto;
        cursor: pointer;
        color: white;
        font-size: 1.1em;
        position: relative;
        .arrow-icon {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 80px;
          transition: 0.4s all ease;
        }
        &:hover {
          .arrow-icon {
            right: 75px;
            color: lightgray;
          }
        }
      }
    }
  }
}
