@import "../../style/responsive";

.basket {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  // backface-visibility: hidden;
  top: 0;
  left: 0;
  z-index: 10003;
  .cartBox {
    width: 40%;
    height: 100%;
    background-color: white;
    margin-right: auto;
    transform: translateX(-100%);
    transition: 0.5s transform ease-in-out;
    @include large {
      width: 60%;
    }
    @include tablet {
      width: 100%;
    }
    .cbContainer {
      .basHeader {
        width: 100%;
        height: 70px;
        background-color: orange;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        padding: 10px;
        :nth-child(1) {
          cursor: pointer;
        }
        :nth-child(2) {
          margin-left: auto;
          margin-right: 10px;
          font-size: 1.1em;
        }
        :nth-child(3) {
          border: 1px solid white;
          border-radius: 6px;
          padding: 5px 10px;
          font-size: 1.05em;
          &:hover {
            background-color: rgba(236, 206, 152, 0.731);
            cursor: pointer;
          }
        }
        .removeAll {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      .cartItems {
        height: calc(100vh - 130px);
        overflow-y: scroll;
        background-color: rgb(244, 240, 240);
        padding: 10px;
        @include tablet {
          padding: 0px;
        }
        &::-webkit-scrollbar {
          display: none;
        }
        .emptyNotif {
          color: purple;
          font-weight: 600;
          font-size: 1.5em;
          margin-top: 20px;
        }
      }
      .cartTotal {
        display: flex;
        background-color: orange;
        height: 60px;
        align-items: center;
        padding: 10px;
        .endShop {
          margin: auto;
          border: 1px solid white;
          padding: 5px 10px;
          border-radius: 6px;
          cursor: pointer;
          color: white;
        }
        .total {
          color: green;
          font-size: 1.2em;
          display: flex;
          flex-direction: column;
          .totalprice {
            font-size: 0.8em;
            color: gray;
          }
          .sum {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
