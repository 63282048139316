@import "../../style/mixin";
@import "../../style/responsive";

.productContent {
  width: 100%;
  display: flex;
  justify-content: center;
  // margin: 30px 0;
  .pcContent {
    display: flex;
    flex: 4;
    @include maxWidth(80%);
    @include large {
      @include maxWidth(95%);
    }
    @include tablet {
      @include maxWidth(95%);
    }
    @include mobile {
      flex-direction: column;
    }
    .basDetails {
      flex: 1.5;
      background-color: white;
      height: max-content;
      position: sticky;
      top: 90px;
      left: 0;
      z-index: 100;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 10px;
      transition: 0.5s all ease;
      text-transform: capitalize;
      @include shadow;
      @include tablet {
        display: none;
      }
      span {
        direction: ltr;
        span {
          margin-right: 5px;
        }
      }
      .location {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-bottom: 15px;
        border-bottom: 0.2px solid lightgray;
        direction: ltr;
      }
      .your {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: green;
        color: white;
        height: 100px;
        direction: ltr;
        gap: 10px;
      }
    }
  }
}
