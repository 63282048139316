@import "../../style/mixin";
@import "../../style/responsive";

.videos {
  width: 100%;
  @include center-h;
  margin-top: 60px;
  @include tablet {
    margin-top: 30px;
  }
  .vContainer {
    @include maxWidth(60%);
    @include tablet {
      @include maxWidth(80%);
    }
    h1 {
      color: $title-color;
      width: max-content;
      margin-bottom: 20px;
      font-size: $title-size;
    }
    h2 {
      color: $subtitle-color;
      font-size: $subtitle-size;
      // width: max-content;
      text-align: right;
      margin-bottom: 15px;
    }
    h3 {
      // margin-top: 10px;
      width: max-content;
      font-size: $subtitle-size;
    }
    p {
      color: $text-color;
      text-align: right;
      line-height: 25px;
      font-size: 1.2em;
      max-width: 90%;
      margin-bottom: 20px;
      text-align: justify;
    }
  }
}

//LTR DIRECTION STYLES
.videos-ltr {
  width: 100%;
  @include center-h;
  margin-top: 60px;
  @include tablet {
    margin-top: 30px;
  }
  .vContainer {
    direction: ltr;
    text-align: left;
    @include maxWidth(60%);
    @include tablet {
      @include maxWidth(80%);
    }
    h1 {
      color: $title-color;
      width: max-content;
      margin-bottom: 20px;
      font-size: $title-size;
    }
    h2 {
      color: $subtitle-color;
      font-size: $subtitle-size;
      // width: max-content;
      text-align: left;
      margin-bottom: 15px;
    }
    h3 {
      // margin-top: 10px;
      width: max-content;
      font-size: $subtitle-size;
    }
    p {
      color: $text-color;
      text-align: left;
      direction: left;
      line-height: 25px;
      font-size: 1.2em;
      max-width: 90%;
      margin-bottom: 20px;
    }
  }
}
