@import "../../style/responsive";
@import "../../style/mixin";

.searchContent {
  width: 100%;
  display: flex;
  justify-content: center;
  background: url("../../../public/images/simple-background.jpg");
  .scContainer {
    width: 100%;
    max-width: 75%;
    @include large {
      max-width: 90%;
    }
    @include mobile {
      max-width: 98%;
    }
    // .searchBox {
    //   display: flex;
    //   justify-content: space-between;
    //   width: 100%;
    //   margin: 50px 0px;
    //   .title {
    //     color: purple;
    //     font-weight: 600;
    //     font-size: 1.5em;
    //   }
    //   .search {
    //     display: flex;
    //     width: 40%;
    //     align-items: center;
    //     justify-content: center;
    //     gap: 15px;
    //     // margin: 30px;
    //     text-align: center;
    //     input {
    //       width: 100%;
    //       padding: 10px;
    //       font-size: 0.9em;
    //     }
    //     .icon {
    //       color: orange;
    //       width: 40px;
    //       height: 40px;
    //       cursor: pointer;
    //     }
    //   }
    //   .filters {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     gap: 10px;
    //     .filter {
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       gap: 10px;
    //       border-left: 1px solid gray;
    //       padding: 5px 10px;
    //       position: relative;
    //       .titr {
    //         font-size: 1.2em;
    //         font-weight: 500;
    //         cursor: pointer;
    //       }
    //       .icon {
    //         cursor: pointer;
    //         z-index: 100;
    //       }
    //       .meals {
    //         position: absolute;
    //         top: 100%;
    //         right: 0;
    //         width: 100%;
    //         overflow: hidden;
    //         z-index: 1000;
    //         .mealContainer {
    //           display: flex;
    //           flex-direction: column;
    //           gap: 10px;
    //           padding: 10px;
    //           transform: translateY(-100%);
    //           background-color: white;
    //           transition: 0.5s transform ease;
    //           border-radius: 6px;
    //           &.active {
    //             transform: translateY(0);
    //           }
    //           .meal {
    //             text-align: right;
    //             padding: 0px 5px;
    //             border-radius: 6px;
    //             cursor: pointer;
    //             font-weight: 500;
    //             color: gray;
    //             &:hover {
    //               background-color: lightgray;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .searchResult {
      margin-bottom: 50px;
      .resultTitle {
        width: max-content;
        color: purple;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1.1em;
      }
      .resultWraper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center;
        margin-bottom: 30px;
      }
      .pagination {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
      }
    }
  }
}
